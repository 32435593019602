import * as React from "react";
import { motion } from "framer-motion";

const icon = {
  hidden: {
    opacity: 0,
    pathLength: 0,
    fill: "rgba(0, 0, 0, 0)",
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    fill: "rgba(255, 255, 255, 1)",
  },
};

export default function Loader() {
  return (
    <section className="loader-main">
      <div className="loader-container">
        <motion.svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 1000"
          className="item"
        >
          <motion.path
            d="M598.13,311.72c-41.79-52.48-100.58-51.12-103.86-51,0,0,0,0,0,0-2.05-.09-61.69-2.07-104,51-43,54-20,115-20,115s9,22,21,38,30,54,35,85,23,30,23,30h89.86s18,1,23-30,23-69,35-85,21-38,21-38S641.13,365.72,598.13,311.72Zm-21,151c-14,21-31.26,64.65-34,77-4,18-10,17-10,17v-101s40-20,40-68-41-66-41-66v58c0,14-15.5,18.5-15.5,18.5H471.77s-15.5-4.5-15.5-18.5v-58s-41,18-41,66,40,68,40,68v101s-6,1-10-17c-2.74-12.35-20-56-34-77s-49-74-9-134c0,0,25-45,92-47,67,2,91.86,47,91.86,47C626.13,388.72,591.13,441.72,577.13,462.72Z"
            variants={icon}
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 3, ease: "easeInOut" },
              fill: { duration: 3, ease: [1, 0, 0.8, 1] },
            }}
          />
          <motion.path
            d="M532.85,589.71H452.71a2.18,2.18,0,0,0-1.89,1.1l-6.07,7.31a2.2,2.2,0,0,0,0,2.19l6.07,7.31a2.17,2.17,0,0,0,1.89,1.09h80.14a2.17,2.17,0,0,0,1.89-1.09l6.07-7.31a2.2,2.2,0,0,0,0-2.19l-6.07-7.31A2.18,2.18,0,0,0,532.85,589.71Z"
            variants={icon}
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 3, ease: "easeInOut" },
              fill: { duration: 3, ease: [1, 0, 0.8, 1] },
            }}
          />
          <motion.path
            d="M527.19,616.06H458.36a1.86,1.86,0,0,0-1.62.93l-5.21,6.29a1.84,1.84,0,0,0,0,1.87l5.21,6.29a1.86,1.86,0,0,0,1.62.93h68.83a1.86,1.86,0,0,0,1.62-.93l5.21-6.29a1.84,1.84,0,0,0,0-1.87L528.81,617A1.86,1.86,0,0,0,527.19,616.06Z"
            variants={icon}
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 3, ease: "easeInOut" },
              fill: { duration: 3, ease: [1, 0, 0.8, 1] },
            }}
          />
          <motion.path
            d="M492.78,641.71h35.5c0,12.07-16,22-35.5,22s-35.5-9.93-35.5-22v-.1Z"
            variants={icon}
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 3, ease: "easeInOut" },
              fill: { duration: 3, ease: [1, 0, 0.8, 1] },
            }}
          />
          <motion.path
            d="M503.28,226V171.41a6,6,0,0,0-2.59-4.93l-3.51-2.42a6,6,0,0,0-6.81,0l-3.5,2.42a6,6,0,0,0-2.59,4.93V226a6,6,0,0,0,2.59,4.93l3.5,2.42a6,6,0,0,0,6.81,0l3.51-2.42A6,6,0,0,0,503.28,226Z"
            variants={icon}
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 3, ease: "easeInOut" },
              fill: { duration: 3, ease: [1, 0, 0.8, 1] },
            }}
          />
          <motion.path
            d="M329.08,381.71H274.47a6,6,0,0,0-4.93,2.6l-2.42,3.5a6,6,0,0,0,0,6.81l2.42,3.5a6,6,0,0,0,4.93,2.59h54.61a6,6,0,0,0,4.93-2.59l2.42-3.5a6,6,0,0,0,0-6.81l-2.42-3.5A6,6,0,0,0,329.08,381.71Z"
            variants={icon}
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 3, ease: "easeInOut" },
              fill: { duration: 3, ease: [1, 0, 0.8, 1] },
            }}
          />
          <motion.path
            d="M710.08,381.71H655.47a6,6,0,0,0-4.93,2.6l-2.42,3.5a6,6,0,0,0,0,6.81l2.42,3.5a6,6,0,0,0,4.93,2.59h54.61a6,6,0,0,0,4.93-2.59l2.42-3.5a6,6,0,0,0,0-6.81l-2.42-3.5A6,6,0,0,0,710.08,381.71Z"
            variants={icon}
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 3, ease: "easeInOut" },
              fill: { duration: 3, ease: [1, 0, 0.8, 1] },
            }}
          />
          <motion.path
            d="M652.8,536.8l-38.61-38.61a6,6,0,0,0-5.32-1.65l-4.19.77a6,6,0,0,0-4.81,4.81l-.77,4.19a6,6,0,0,0,1.65,5.32l38.61,38.61a6,6,0,0,0,5.32,1.65l4.19-.77a6,6,0,0,0,4.81-4.81l.77-4.19A6,6,0,0,0,652.8,536.8Z"
            variants={icon}
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 3, ease: "easeInOut" },
              fill: { duration: 3, ease: [1, 0, 0.8, 1] },
            }}
          />
          <motion.path
            d="M639.36,229.19,600.75,267.8a6,6,0,0,0-1.65,5.32l.77,4.19a6,6,0,0,0,4.81,4.81l4.19.77a6,6,0,0,0,5.32-1.65l38.61-38.61a6,6,0,0,0,1.65-5.32l-.77-4.19a6,6,0,0,0-4.81-4.81l-4.19-.77A6,6,0,0,0,639.36,229.19Z"
            variants={icon}
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 3, ease: "easeInOut" },
              fill: { duration: 3, ease: [1, 0, 0.8, 1] },
            }}
          />
          <motion.path
            d="M383.8,267.8l-38.61-38.61a6,6,0,0,0-5.32-1.65l-4.19.77a6,6,0,0,0-4.81,4.81l-.77,4.19a6,6,0,0,0,1.65,5.32l38.61,38.61a6,6,0,0,0,5.32,1.65l4.19-.77a6,6,0,0,0,4.81-4.81l.77-4.19A6,6,0,0,0,383.8,267.8Z"
            variants={icon}
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 3, ease: "easeInOut" },
              fill: { duration: 3, ease: [1, 0, 0.8, 1] },
            }}
          />
          <motion.path
            d="M370.36,498.19,331.75,536.8a6,6,0,0,0-1.65,5.32l.77,4.19a6,6,0,0,0,4.81,4.81l4.19.77a6,6,0,0,0,5.32-1.65l38.61-38.61a6,6,0,0,0,1.65-5.32l-.77-4.19a6,6,0,0,0-4.81-4.81l-4.19-.77A6,6,0,0,0,370.36,498.19Z"
            variants={icon}
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 3, ease: "easeInOut" },
              fill: { duration: 3, ease: [1, 0, 0.8, 1] },
            }}
          />
          <motion.path
            d="M170.64,710.86h21.42a1.9,1.9,0,0,1,1.9,1.9v63.61a1.89,1.89,0,0,0,1.89,1.9h55.38a1.9,1.9,0,0,0,1.9-1.9V712.76a1.89,1.89,0,0,1,1.89-1.9h21.49a1.89,1.89,0,0,1,1.89,1.9v78.81a1.89,1.89,0,0,1-1.89,1.89H170.64a1.9,1.9,0,0,1-1.9-1.89V712.76A1.9,1.9,0,0,1,170.64,710.86Z"
            variants={icon}
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 3, ease: "easeInOut" },
              fill: { duration: 3, ease: [1, 0, 0.8, 1] },
            }}
          />
          <motion.path
            d="M308.84,711h15.21a2,2,0,0,1,2,2v63.51a2,2,0,0,0,2,2H408.5a2,2,0,0,1,2,2v10.87a2,2,0,0,1-2,2H308.84a2,2,0,0,1-2-2V713A2,2,0,0,1,308.84,711Z"
            variants={icon}
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 3, ease: "easeInOut" },
              fill: { duration: 3, ease: [1, 0, 0.8, 1] },
            }}
          />
          <motion.path
            d="M437.52,711h15.21a2,2,0,0,1,2,2v63.51a2,2,0,0,0,2,2h80.38a2,2,0,0,1,2,2v10.87a2,2,0,0,1-2,2H437.52a2,2,0,0,1-2-2V713A2,2,0,0,1,437.52,711Z"
            variants={icon}
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 3, ease: "easeInOut" },
              fill: { duration: 3, ease: [1, 0, 0.8, 1] },
            }}
          />
          <motion.path
            d="M745.89,732.82s2.43-9.47,9.47-9.47,10.79,4.23,11.56,9.23a2.63,2.63,0,0,1,0,.49v56.71a2.91,2.91,0,0,0,2.91,2.91h12.38a2.91,2.91,0,0,0,2.92-2.91V733.39a3,3,0,0,1,.27-1.24c.95-2,4.4-8,11.64-8,8.68,0,13.71,7.75,13.79,8.64s3.19,8,3.19,22.76S808.7,790.65,802.32,795s-13.92,6.9-16.6,6.9H739.57s-19.34-1.66-19.34,20v5.43a2.91,2.91,0,0,0,2.92,2.91h12.69a2.89,2.89,0,0,0,2.84-2.26c.89-4,3.44-12,9.81-12h43.25c.26,0,2.31-.43,2.56-.49,3.32-.88,26.69-7.59,33.51-29.13,6.89-21.8,5.63-35.75,5.46-37.3,0-.1,0-.2,0-.3,0-2.64-.51-39.27-34.46-39.34h-.2c-1.16.06-12.44.79-19,6.33a2.93,2.93,0,0,1-3.8.05c-2.91-2.43-9.3-6.39-20.14-6.39C740,709.33,727,721.67,727,732.78c0,9.47.21,46.37.28,57a2.9,2.9,0,0,0,2.91,2.89H743a2.91,2.91,0,0,0,2.91-2.91v-57Z"
            variants={icon}
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 3, ease: "easeInOut" },
              fill: { duration: 3, ease: [1, 0, 0.8, 1] },
            }}
          />
          <motion.path
            d="M377.9,718.45h-2.85a10.8,10.8,0,0,0-1.3-3.15l2-2a.79.79,0,0,0,0-1.13l-2.37-2.38a.81.81,0,0,0-1.14,0l-2,2a10.85,10.85,0,0,0-3.14-1.3v-2.85a.8.8,0,0,0-.8-.8h-3.36a.8.8,0,0,0-.81.8v2.85a10.85,10.85,0,0,0-3.14,1.3l-2-2a.81.81,0,0,0-1.14,0l-2.37,2.38a.79.79,0,0,0,0,1.13l2,2a10.8,10.8,0,0,0-1.3,3.15h-2.85a.8.8,0,0,0-.8.8v3.36a.8.8,0,0,0,.8.8h2.85a10.74,10.74,0,0,0,1.3,3.14l-2,2a.79.79,0,0,0,0,1.13l2.37,2.38a.81.81,0,0,0,1.14,0l2-2a10.58,10.58,0,0,0,3.14,1.3v2.85a.8.8,0,0,0,.81.8h3.36a.8.8,0,0,0,.8-.8v-2.85a10.58,10.58,0,0,0,3.14-1.3l2,2a.81.81,0,0,0,1.14,0l2.37-2.38a.79.79,0,0,0,0-1.13l-2-2a10.74,10.74,0,0,0,1.3-3.14h2.85a.8.8,0,0,0,.8-.8v-3.36A.8.8,0,0,0,377.9,718.45Zm-13.28,8.3a6,6,0,1,1,6-6A6,6,0,0,1,364.62,726.75Z"
            variants={icon}
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 3, ease: "easeInOut" },
              fill: { duration: 3, ease: [1, 0, 0.8, 1] },
            }}
          />
          <motion.path
            d="M662.66,709.33A60.94,60.94,0,0,0,639.79,714a2.12,2.12,0,0,1-1.08.22l-1-.06a2.18,2.18,0,0,1-.9-.26c-2-1.06-11.39-5.52-29.72-5.52-20.81,0-49.66,15.58-49.66,49.66s22.85,35.75,24.89,35.75,22.72.89,22.72-29c0-22.79-13.67-27.3-20.15-28.16a2.18,2.18,0,0,1-1.43-3.49c2.83-3.65,7.94-6.59,17.37-9.08,8.46-2.22,16.91-.29,21.26,1.09a2.15,2.15,0,0,1,1,3.36c-2.93,3.92-8.14,13-8.14,28.36,0,21.32,8,36.9,24.64,36.9s24-21.2,24-35.75c0-11.7-6.63-24.9-9.23-30a2.17,2.17,0,0,1,1.54-3.12,43.61,43.61,0,0,1,7.18-.45c10,0,23.36,9,23.36,28.1,0,15.18-7.85,27.07-11.1,31.35a2.15,2.15,0,0,0,.46,3.06l9.46,6.88a2.16,2.16,0,0,0,2.65-.08c4.28-3.54,17.3-16.56,17.3-43.76C705.3,717.4,675,709.33,662.66,709.33ZM587.45,764.8c.55,7.74-1.88,14.22-5.43,14.48s-6.88-5.82-7.43-13.56,1.88-14.23,5.43-14.48S586.9,757.06,587.45,764.8Zm51.72,13.62c-3.81,0-7.89-9.12-7.89-20.36s4.08-20.37,7.89-20.37,7.89,9.12,7.89,20.37S643,778.42,639.17,778.42Z"
            variants={icon}
            initial="hidden"
            animate="visible"
            transition={{
              default: { duration: 3, ease: "easeInOut" },
              fill: { duration: 3, ease: [1, 0, 0.8, 1] },
            }}
          />
        </motion.svg>
      </div>
    </section>
  );
}
