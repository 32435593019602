import React from "react";
import ContentLoader from "react-content-loader";

export const NewsL = (props) => (
  <>
    <div className="d-flex">
      <ContentLoader
        width={350}
        height={450}
        viewBox="0 0 450 400"
        backgroundColor="#171717"
        foregroundColor="#212121"
        {...props}
      >
        <rect x="60" y="-50" rx="4" ry="7" width="280" height="750" />
      </ContentLoader>
      <ContentLoader
        className="home"
        width={350}
        height={450}
        viewBox="0 0 450 400"
        backgroundColor="#171717"
        foregroundColor="#212121"
        {...props}
      >
        <rect x="60" y="-50" rx="4" ry="7" width="280" height="750" />
      </ContentLoader>
      <ContentLoader
        className="home"
        width={350}
        height={450}
        viewBox="0 0 450 400"
        backgroundColor="#171717"
        foregroundColor="#212121"
        {...props}
      >
        <rect x="60" y="-50" rx="4" ry="7" width="280" height="750" />
      </ContentLoader>
      <ContentLoader
        className="home"
        width={350}
        height={450}
        viewBox="0 0 450 400"
        backgroundColor="#171717"
        foregroundColor="#212121"
        {...props}
      >
        <rect x="60" y="-50" rx="4" ry="7" width="280" height="750" />
      </ContentLoader>
    </div>
  </>
);

export const CrewL = (props) => (
  <>
    <section className="row align-justify-center mt-5">
      <ContentLoader
        className="col-12 col-md-6 col-lg-4 col-xl-3 px-3 m-3"
        backgroundColor="#171717"
        foregroundColor="#212121"
        viewBox="10 0 500 1550"
        height={800}
        width={550}
        {...props}
      >
        <circle cx="110" cy="110" r="100" />
        <rect x="230" y="70" width="190" height="17" />
        <rect x="230" y="110" width="300" height="17" />
        <rect x="230" y="150" width="150" height="17" />

        <circle cx="110" cy="500" r="100" />
        <rect x="230" y="460" width="170" height="17" />
        <rect x="230" y="500" width="296" height="17" />
        <rect x="230" y="540" width="150" height="17" />

        <circle cx="110" cy="890" r="100" />
        <rect x="230" y="850" width="170" height="17" />
        <rect x="230" y="890" width="296" height="17" />
        <rect x="230" y="930" width="150" height="17" />

        <circle cx="110" cy="1280" r="100" />
        <rect x="230" y="1240" width="170" height="17" />
        <rect x="230" y="1280" width="296" height="17" />
        <rect x="230" y="1310" width="150" height="17" />
      </ContentLoader>
      <ContentLoader
        className="col-12 col-md-6 col-lg-4 col-xl-3 px-3 m-3"
        backgroundColor="#171717"
        foregroundColor="#212121"
        viewBox="10 0 500 1550"
        height={800}
        width={550}
        {...props}
      >
        <circle cx="110" cy="110" r="100" />
        <rect x="230" y="70" width="190" height="17" />
        <rect x="230" y="110" width="300" height="17" />
        <rect x="230" y="150" width="150" height="17" />

        <circle cx="110" cy="500" r="100" />
        <rect x="230" y="460" width="170" height="17" />
        <rect x="230" y="500" width="296" height="17" />
        <rect x="230" y="540" width="150" height="17" />

        <circle cx="110" cy="890" r="100" />
        <rect x="230" y="850" width="170" height="17" />
        <rect x="230" y="890" width="296" height="17" />
        <rect x="230" y="930" width="150" height="17" />

        <circle cx="110" cy="1280" r="100" />
        <rect x="230" y="1240" width="170" height="17" />
        <rect x="230" y="1280" width="296" height="17" />
        <rect x="230" y="1310" width="150" height="17" />
      </ContentLoader>
      <ContentLoader
        className="col-12 col-md-6 col-lg-4 col-xl-3 px-3 m-3"
        backgroundColor="#171717"
        foregroundColor="#212121"
        viewBox="10 0 500 1550"
        height={800}
        width={550}
        {...props}
      >
        <circle cx="110" cy="110" r="100" />
        <rect x="230" y="70" width="190" height="17" />
        <rect x="230" y="110" width="300" height="17" />
        <rect x="230" y="150" width="150" height="17" />

        <circle cx="110" cy="500" r="100" />
        <rect x="230" y="460" width="170" height="17" />
        <rect x="230" y="500" width="296" height="17" />
        <rect x="230" y="540" width="150" height="17" />

        <circle cx="110" cy="890" r="100" />
        <rect x="230" y="850" width="170" height="17" />
        <rect x="230" y="890" width="296" height="17" />
        <rect x="230" y="930" width="150" height="17" />

        <circle cx="110" cy="1280" r="100" />
        <rect x="230" y="1240" width="170" height="17" />
        <rect x="230" y="1280" width="296" height="17" />
        <rect x="230" y="1310" width="150" height="17" />
      </ContentLoader>
    </section>
  </>
);

export const LeagueL = (props) => {
  return (
    <>
      <section className="mt-5 justify-content-center">
        <ContentLoader
          viewBox="0 0 500 250"
          height={280}
          width={400}
          backgroundColor="#171717"
          foregroundColor="#212121"
          {...props}
        >
          <circle cx="100" cy="80" r="60" />
          <rect x="10" y="180" rx="0" ry="0" width="180" height="25" />
          <circle cx="400" cy="80" r="60" />
          <rect x="310" y="180" rx="0" ry="0" width="180" height="25" />
        </ContentLoader>
      </section>
    </>
  );
};
