import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function SocialHandles({ img, src, size = "2x" }) {
  return (
    <a className="my-3 mx-2" href={src} target="_blank" rel="noreferrer">
      <FontAwesomeIcon
        className=""
        color="#ebebea"
        opacity="0.8"
        size={size}
        icon={img}
      />
    </a>
  );
}
