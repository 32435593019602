import React, { useState } from "react";
import Terminal, { ColorMode, LineType } from "react-terminal-ui";
import emailjs from "emailjs-com";

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const TerminalController = (props = {}) => {
  const [terminalLineData, setterminalLineData] = useState([
    {
      type: LineType.Output,
      value: "Not a believer? Give us a chance, you wont regret it",
    },
    {
      type: LineType.Input,
      value: "Drop us your mail, and we will get back to you the earliest",
    },
  ]);

  function sleep(milliseconds) {
    const date = Date.now();
    let currentDate = null;
    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
  }

  function onInput(input) {
    let ld = [...terminalLineData];
    ld.push({ type: LineType.Input, value: input });
    console.log("New terminal input received: " + input);
    if (validateEmail(input)) {
      templateParams = {
        email: input,
      };
      sendEmail();
      sleep(2000);
      ld.push({ type: LineType.Output, value: "Hack started..." });
      ld.push({ type: LineType.Output, value: "Injecting chocolates..." });
      ld.push({ type: LineType.Output, value: "Finding water on mars..." });
      ld.push({ type: LineType.Output, value: "Inviting aliens..." });
      ld.push({ type: LineType.Output, value: "Successfully hacked NASA!!" });
    } else {
      ld.push({
        type: LineType.Output,
        value: "Please enter a valid email id!",
      });
    }
    setterminalLineData(ld);
  }

  var templateParams;

  const sendEmail = (e) => {
    emailjs
      .send(
        `${process.env.REACT_APP_SERVICE_ID}`,
        `${process.env.REACT_APP_TEMPLATE_ID}`,
        templateParams,
        `${process.env.REACT_APP_USER_ID}`
      ) //params: ("SERVICE_ID", "TEMPLATE_ID", templateParams, "USER_ID")
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="text-left d-flex justify-content-center justify-content-md-start">
      <Terminal
        name="Reach Out To Us"
        colorMode={ColorMode.Dark}
        lineData={terminalLineData}
        onInput={onInput}
      />
    </div>
  );
};
