import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { Canvas } from "react-three-fiber";
import {
  Triangle1,
  Diamond,
  Torusknot,
  Ring,
  Triangle,
  Icosahedron,
  Sphere1,
  Light1,
  Light2,
  Light3,
} from "../components/Events/Shapes";
import Hacktoberfest from "../assets/images/Hack.jpeg";
import FadeIn from "../components/FadeIn";
import Baselayout from "../components/BaseLayout";
import Marquee from "react-fast-marquee";
import { motion } from "framer-motion";
import react from "../assets/images/react.png";
import code from "../assets/images/code.png";
import flutter from "../assets/images/flutter.png";
import CS from "../assets/images/CS.png";
import pen from "../assets/images/pen.png";
import IOT from "../assets/images/IOT.png";

export default function Event() {
  function WorkshopCard(props) {
    return (
      <div
        className="card card-body m-5 d-flex align-items-center justify-content-center"
        style={{
          backgroundColor: "black",
          borderColor: "#409cfe",
          boxShadow: "2px 2px 1px #f687ff",
          borderWidth: "2px",
          borderRadius: "15px",
          width: "18rem",
          height: "28rem",
        }}
      >
        <div className="">
          <div>
            <img style={{ width: "100px" }} alt="Card cap" src={props.src} />
          </div>
          <p className="h4 font-weight-bold mt-2 mb-3">{props.workshopTitle}</p>
          {props.speakerLinks.map((handle) => (
            <a
              href={"https://twitter.com/" + handle}
              target="_blank"
              rel="noreferrer"
            >
              <div style={{ color: "#6c94d4" }}>{"@" + handle}</div>
            </a>
          ))}

          <a
            href={props.link}
            rel="noreferrer"
            target="_blank"
            className="m-3 btn "
            style={{
              color: "white",
              borderColor: "#409cfe",
              boxShadow: "2px 2px 1px #f687ff",
              backgroundColor: "black",
            }}
          >
            Learn More
          </a>
        </div>
      </div>
    );
  }

  return (
    <>
      <FadeIn>
        <Baselayout>
          <div className="text-center mt-5 pt-md-3 pt-1">
            <h1 className="mt-5 pb-3 heading text-center event font-weight-bold g-text2">
              Our Events
            </h1>
            <div className="divider div-transparent"></div>
            <VerticalTimeline>
              <VerticalTimelineElement
                className="vertical-timeline-element--education text-left"
                date="IDEATHON 1"
                contentStyle={{
                  background: "rgb(0,0,0)",
                  color: "rgb(211,211,211)",
                }}
                iconStyle={{ background: "#000000" }}
              >
                <Canvas className="retrofy" colorManagement>
                  <ambientLight intensity={0.3} />
                  <pointLight position={[10, 0, 0]} intensity={0.5} />

                  <Light1 brightness={45} color={"#3751DD"} />
                  <Light2 brightness={45} color={"#C229C2"} />
                  <Light3 brightness={45} color={"#18A2C2"} />

                  <Icosahedron />
                </Canvas>

                <p>
                  Ideathon is a victorious event that went on for 3 days, with
                  24 teams presenting their distinctive ideas with a jury panel
                  of 5 judges .
                </p>
              </VerticalTimelineElement>

              <VerticalTimelineElement
                className="vertical-timeline-element--education text-left"
                style={{ TextSize: "30px" }}
                date=" IDEATHON 2"
                contentStyle={{
                  background: "rgb(0,0,0)",
                  color: "rgb(211,211,211)",
                }}
                iconStyle={{ background: "#000000" }}
              >
                <Canvas className="retrofy" colorManagement>
                  <ambientLight intensity={0.3} />
                  <pointLight position={[10, 0, 0]} intensity={0.5} />
                  <Triangle />

                  <Light1 brightness={45} color={"#3751DD"} />
                  <Light2 brightness={45} color={"#C229C2"} />
                  <Light3 brightness={45} color={"#18A2C2"} />
                </Canvas>
                <p>
                  Pattarai's flagship event had a total of 140 registrations and
                  more than 300 participants from schools and colleges all over
                  India with a jury panel of 11 judges from US, Canada, India
                  were put together for the finals of Ideathon - II.
                </p>
              </VerticalTimelineElement>

              <VerticalTimelineElement
                className="vertical-timeline-element--education text-left"
                date="Festx"
                contentStyle={{
                  background: "rgb(0,0,0)",
                  color: "rgb(211,211,211)",
                }}
                iconStyle={{ background: "#000000" }}
                // icon={<WorkIcon />}
              >
                <Canvas className="retrofy" colorManagement>
                  <ambientLight intensity={0.3} />
                  <pointLight position={[10, 0, 0]} intensity={0.5} />

                  <Ring />

                  <Light1 brightness={45} color={"#3751DD"} />
                  <Light2 brightness={45} color={"#C229C2"} />
                  <Light3 brightness={45} color={"#18A2C2"} />
                </Canvas>
                <p>
                  Festx was a 2-day online symposium with a total of 9 events
                  i.e 5 Technical and 4 Non-Technical events with a total of 250
                  registrations and 368 participants with an amazing jury panel
                  of 15 judges .
                </p>
              </VerticalTimelineElement>

              <VerticalTimelineElement
                className="vertical-timeline-element--education text-left"
                date="Theervu 2020 "
                contentStyle={{
                  background: "rgb(0,0,0)",
                  color: "rgb(211,211,211)",
                }}
                iconStyle={{ background: "#000000" }}
                // icon={<SchoolIcon />}
              >
                <Canvas className="retrofy" colorManagement>
                  <ambientLight intensity={0.3} />
                  <pointLight position={[10, 0, 0]} intensity={0.5} />
                  <Sphere1 />

                  <Light1 brightness={45} color={"#3751DD"} />
                  <Light2 brightness={45} color={"#C229C2"} />
                  <Light3 brightness={45} color={"#18A2C2"} />
                </Canvas>
                <p>
                  Theervu 2020 an inter-college hackathon with the theme 'use
                  technology to help unorganized sector' where three winners
                  were chosen by 7 judges after the run of 48hrs working on the
                  prototypes.
                </p>
              </VerticalTimelineElement>

              <VerticalTimelineElement
                className="vertical-timeline-element--education text-left"
                date="INTACTO 2020"
                contentStyle={{
                  background: "rgb(0,0,0)",
                  color: "rgb(211,211,211)",
                }}
                iconStyle={{ background: "#000000" }}
                // icon={<SchoolIcon />}
              >
                <Canvas className="retrofy" colorManagement>
                  <ambientLight intensity={0.3} />
                  <pointLight position={[10, 0, 0]} intensity={0.5} />
                  <Torusknot />

                  <Light1 brightness={45} color={"#3751DD"} />
                  <Light2 brightness={45} color={"#C229C2"} />
                  <Light3 brightness={45} color={"#18A2C2"} />
                </Canvas>
                <p>
                  A complete virtual treasure hunt with 50 teams including 150
                  participants which was solved by applying programming
                  fundamentals, chemistry, physics, and mathematics.
                </p>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className="vertical-timeline-element--education text-left"
                date="Learnzeit"
                contentStyle={{
                  background: "rgb(0,0,0)",
                  color: "rgb(211,211,211)",
                }}
                iconStyle={{ background: "#000000" }}
                // icon={<SchoolIcon />}
              >
                <Canvas className="retrofy" colorManagement>
                  <ambientLight intensity={0.3} />
                  <pointLight position={[10, 0, 0]} intensity={0.5} />
                  <Diamond />

                  <Light1 brightness={45} color={"#3751DD"} />
                  <Light2 brightness={45} color={"#C229C2"} />
                  <Light3 brightness={45} color={"#18A2C2"} />
                </Canvas>
                <p>
                  An open platform to share knowledge and thoughts is open for
                  everyone even outside the crew. We look out for creative and
                  innovative content, not narrowing it down to any particular
                  stream be it technical or non-technical.
                </p>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className="vertical-timeline-element--education text-left"
                date="Mentee Conclave"
                contentStyle={{
                  background: "rgb(0,0,0)",
                  color: "rgb(211,211,211)",
                }}
                iconStyle={{ background: "#000000" }}
                // icon={<SchoolIcon />}
              >
                <Canvas className="retrofy" colorManagement>
                  <ambientLight intensity={0.3} />
                  <pointLight position={[10, 0, 0]} intensity={0.5} />
                  <Triangle1 />

                  <Light1 brightness={45} color={"#3751DD"} />
                  <Light2 brightness={45} color={"#C229C2"} />
                  <Light3 brightness={45} color={"#18A2C2"} />
                </Canvas>
                <p>
                  A mentorship program was initiated for the budding engineers
                  who were interested in becoming sound professionals. And as a
                  result of this 118-day mentorship program, which included 44
                  mentees and 21 mentors, each mentee completed with original
                  ideas and solid expertise.
                </p>
              </VerticalTimelineElement>
            </VerticalTimeline>
            <div style={{ marginTop: "200px" }}>
              <h1 className="heading text-center event font-weight-bold g-text2">
                Workshops
              </h1>
              <p>
                Reach us if you want to know more about our workshops
                <br />
                Do you want to share your expertise with the world? Reach our
                <a style={{ color: "#6c94d4" }} href="mailto:hello@pattarai.in">
                  {" "}
                  Director of Activities{" "}
                </a>
                for a platform to express your talent
              </p>
              <Marquee
                pauseOnHover
                direction="left"
                speed={80}
                gradient
                gradientWidth={150}
                gradientColor={[0, 0, 0]}
              >
                <motion.div
                  exit={{ opacity: 0 }}
                  initial="initial"
                  animate="animate"
                >
                  <motion.div className="d-flex">
                    <WorkshopCard
                      src={code}
                      workshopTitle={"Web Development"}
                      speakerLinks={["the_danielmark"]}
                      link={
                        "https://www.youtube.com/watch?v=_xFh0AXBn1g&list=PLLCdGWbcw9uxOviv7U0SV33u1Jc5i5IRU"
                      }
                    />

                    <WorkshopCard
                      src={pen}
                      workshopTitle={"Design Workshop"}
                      speakerLinks={[
                        "fabianferno",
                        "GregAbijesh",
                        "Jonathanryndolf",
                        "MarkGer86172034",
                      ]}
                      link={
                        "https://www.youtube.com/watch?v=2-REKXIc9To&list=PLLCdGWbcw9uwpTE4XJqKp4OIJU0h_6bE8"
                      }
                    />
                    <WorkshopCard
                      src={react}
                      workshopTitle={"ReactJS Workshop"}
                      speakerLinks={["the_danielmark"]}
                      link={
                        "https://www.youtube.com/watch?v=mk4sVUKkYkc&list=PLLCdGWbcw9uwhUPaCmtQlHlMKyE6R7a1P"
                      }
                    />
                    <WorkshopCard
                      src={IOT}
                      workshopTitle={"IoT BootCamp"}
                      speakerLinks={["theflyingrahul"]}
                      link={
                        "https://www.youtube.com/watch?v=a2p0f_6gBx0&list=PLLCdGWbcw9uxaNS7qXzvMCbr22i2cUexs"
                      }
                    />
                    <WorkshopCard
                      src={Hacktoberfest}
                      workshopTitle={"Hacktoberfest"}
                      speakerLinks={[
                        "theflyingrahul",
                        "fabianferno",
                        "the_danielmark",
                        "crispinr_",
                      ]}
                      link={"/hacktoberfest"}
                    />

                    <WorkshopCard
                      src={flutter}
                      workshopTitle={"Flutter Dev Workshop"}
                      speakerLinks={["theflyingrahul"]}
                      link={
                        "https://www.youtube.com/watch?v=lQ6WT9lTCbY&list=PLLCdGWbcw9uzzN_QuKqa1dpLTE3y8lK9o"
                      }
                    />
                    <WorkshopCard
                      src={CS}
                      workshopTitle={"Computer Bootcamp"}
                      speakerLinks={["fabianferno"]}
                      link={"/events"}
                    />
                  </motion.div>
                </motion.div>
              </Marquee>
            </div>
          </div>
        </Baselayout>
      </FadeIn>
    </>
  );
}
