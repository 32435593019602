import FadeIn from "../components/FadeIn";
import Baselayout from "../components/BaseLayout";

export default function Policy() {
  return (
    <>
      <FadeIn>
        <Baselayout>
          <section
            className="d-flex text-justify p-5 container"
            style={{ width: "100vw" }}
          >
            <div>
              <h1 className="text-center m-5">Privacy policy</h1>
              <p className="text-justify" style={{ fontWeight: "150" }}>
                This Privacy Policy explains how Pattarai will process the
                personal information that is collected in the registration form.
                You can find this form on our website .
              </p>
              <br />
              <p className="text-justify" style={{ fontWeight: "150" }}>
                This document explains the Privacy Policy that is in force at
                each moment, being your obligation as user or attendee to check
                it periodically to verify that you still agree with it. Please,
                in case you do not agree with our Privacy Policy, do not
                continue browsing our website or do not include information in
                the forms available on our website.
              </p>
              <br />
              <h6>DATA CONTROLLER</h6>
              <p className="text-justify" style={{ fontWeight: "150" }}>
                Pattarai is the data controller of all the data collected. The
                website is owned by Pattarai. You can find all of our contact
                information on our website .
              </p>
              <br />
              <h6>DATA WE COLLECT</h6>
              <p className="text-justify" style={{ fontWeight: "150" }}>
                We collect the communication information through the ‘Buy
                Tickets’ form and ‘Registration’ form on our website. This
                information is necessary for sending you our newsletter and
                information about the event.
              </p>
              <br />
              <h6>PURPOSE OF THE TREATMENT</h6>
              <p className="text-justify" style={{ fontWeight: "150" }}>
                The personal data provided may be used for the following
                purpose: Sending you commercial communications, to keep you
                informed about the future editions of the Elevate Conference or
                other professional events promoted by Pattarai.
              </p>
              <br />
              <p className="text-justify" style={{ fontWeight: "150" }}>
                Disclaimer: We will not make profiling or automated decisions
                from the data you provide us with.
              </p>
              <br />
              <h6>DATA CONSERVATION</h6>
              <p className="text-justify" style={{ fontWeight: "150" }}>
                The personal data provided will not be stored longer than
                necessary for the data processing and, specifically, regarding
                the data we request in each of the forms.
              </p>
              <br />
              <p className="text-justify" style={{ fontWeight: "150" }}>
                Data of the Newsletter’s subscription form: We will keep your
                data until you request us to unsubscribe. You can be removed
                from our newsletter list by sending an email with ‘unsubscribe’
                in the subject line to elevate@pattarai.in
              </p>
              <br />
              <p className="text-justify" style={{ fontWeight: "150" }}>
                In case that you revoke your consent to the processing of your
                data, we will proceed to the deletion of your data. This means
                that we will not be able to access your information and it will
                only be stored during the periods established by the applicable
                regulations to respond to requests from the competent
                authorities and to deal with possible responsibilities arising
                from the processing.
              </p>
              <br />
              <h6>LEGITIMATION OF DATA PROCESSING</h6>
              <p className="text-justify" style={{ fontWeight: "150" }}>
                The legal basis for processing your data is your consent. You
                can revoke it at any time, but the data processing made
                previously for that purpose will not lose its legality since the
                consent has been revoked.
              </p>
              <br />
              <h6>COMMUNICATION OF THE DATA</h6>
              <p className="text-justify" style={{ fontWeight: "150" }}>
                Pattarai will not transfer the personal data of the users or/and
                attendees, to any third party without a legal basis that
                legitimizes that data processing. This means that we will not
                share your data with third parties except:
              </p>

              <li className="text-justify" style={{ fontWeight: "150" }}>
                When we have a legal obligation, we will be able to communicate
                your information to the authorities and entities that require it
                to us
              </li>
              <li className="text-justify" style={{ fontWeight: "150" }}>
                For the correct provision of the services requested by the user.
                Pattarai’s Data Processors (that is to say, our service
                providers) will be able to access your data to be able to
                develop the functions for which they were hired, among others:
                being able to manage your registration to the Event, accessing
                the Event and to the Event’s Website. Also, we want to inform
                you that these service providers sign Data Processing Contracts
                that cover all the appropriate security measures to guarantee
                the protection of your data, by the data protection regulations.
              </li>
              <p className="text-justify" style={{ fontWeight: "150" }}>
                We will not make international transfers of your data.
              </p>
              <br />
              <h6>YOUR RIGHTS AND HOW WE EXERCISE THEM</h6>
              <h6>Right of access</h6>
              <p className="text-justify" style={{ fontWeight: "150" }}>
                You have the right to request information about what personal
                data of yours we are dealing with if any. The information that
                we can give you in this regard is what type of data is, what is
                the purpose of their processing or if we have communicated them
                to third parties or Data Processors, among other aspects
              </p>
              <br />
              <h6>Right of rectification</h6>
              <p className="text-justify" style={{ fontWeight: "150" }}>
                The information you provide us must be correct and accurate. In
                case you think we have to rectify some information you can
                request us to correct it as soon as possible.
              </p>
              <br />
              <h6>Right of suppression and limitation of the treatment</h6>
              <p className="text-justify" style={{ fontWeight: "150" }}>
                In case you do not want us to continue using your data, or that
                we only use them for limited purposes, you can exercise this
                right by indicating if you want us to proceed in this way,
                either because you do not want us to continue processing the
                data for which we collected them, or because the reason that
                originated their processing has disappeared.
              </p>
              <br />
              <h6>
                Right of opposition and not to be the object of individualized
                decisions
              </h6>
              <p className="text-justify" style={{ fontWeight: "150" }}>
                We will not process your information in case you oppose the data
                processing, nor to make individualized decisions nor to
                elaborate personalized profiles.
              </p>
              <br />
              <h6>Right to complain</h6>
              <p className="text-justify" style={{ fontWeight: "150" }}>
                If you consider that your rights have been violated, you can
                complain to the Elevate - Public Relations Committee
              </p>
              <br />
              <p className="text-justify" style={{ fontWeight: "150" }}>
                By the IND IT Act, you, as an attendee (including Speakers and
                Sponsors) are informed about the collection of your data and
                your rights, as described above. You can exercise the
                above-mentioned rights by notice in writing at website
                accompanied with a copy of your ID or similar document that
                allows the Organizers to properly identify you.
              </p>
              <br />
            </div>
          </section>
        </Baselayout>
      </FadeIn>
    </>
  );
}
