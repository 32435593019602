import FadeIn from "../components/FadeIn";
import Baselayout from "../components/BaseLayout";
import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ControlledCarousel from "../components/DomainsGenesis";
import Logo from '../assets/images/Avatar.png'
import Nogallery from '../assets/images/nothinghere.png'
import Polygons from '../assets/images/polygons.svg'


export default function Event() {
  return (
    <div style={{ backgroundColor: "#6E59EC", minHeight: "100vh" }} >
      <FadeIn>
        <Header />
        <main>
          {/* Your content here */}
          <div className="backgroundcontainer snap-y snap-mandatory overflow-y-scroll h-screen flex-grow z-0" style={{
              backgroundImage: `url(${Polygons})`,
              backgroundRepeat: 'repeat',
              backgroundSize: 'cover',
              minHeight: '50%',
              scale: 0.5,
              position: 'relative'
            }}> 
          <div className="first-vh snap-always snap-center" id="page1">
          <p className="karatina-text genesis">Genesis</p>
          <div className="scroll-down">
            <div className="scroll-down-top"></div>
          </div>
          <p className="scroll-down-text" >Scroll Down..</p>
          </div>

          <div className="middle-vh snap-always snap-center" id="page2">
            <img className="logo" src={Logo} alt="Pattarai"/>
            <div className="about-div">
              <h1 className="what">What is Genesis ??</h1>
              <p className="whatelse">Genesis: Dive into the exciting realm of engineering at our exclusive event tailored for high school graduates, where hands-on workshops, inspiring talks, and interactive sessions await!</p>
              <p className="whatelse">Kickstart your engineering adventure with a blend of fun and learning at our exclusive event for high school graduates. Dive into interactive workshops, enjoy engaging activities, and discover the thrilling world of technology and innovation!</p>
            </div>
          </div>
          </div>
          <div className="second-vh snap-always snap-center" id="page3">
          <p className="karatina-text domains">Domains</p>
          <div className="carousel-domains">
          <ControlledCarousel />
          </div>
          </div>
          <div className="third-vh snap-always snap-center" id="page4">
            <div className="topbar">
              <div className="design-left"></div>
              <h1 className="gallery-text" >Gallery</h1>
              <div className="design-right"></div>
              
            </div>
            <div className="gallery">
              <img className="nogallery" src={Nogallery} alt="" />
            </div>
          </div>
        </main>
        <Footer />
      </FadeIn>
    </div>
  );
}
