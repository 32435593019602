import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Web from '../assets/images/web-development.png';
import IOT from '../assets/images/iot-domain.png'
import AI from '../assets/images/ai-tools.png'
import '../assets/scss/genesis.scss'
function ControlledCarousel() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel className='carousel' style={{ display: 'flex',width: '100%' }} >
      
      <Carousel.Item>
      <img className='domain-logo d-block mx-auto' src={Web} alt="Web Development" /> {/* Render the image */}
          <div className='titledesc'>
          <h3>Web Development</h3>
          <p>Workshop to build your first website</p>
          </div>
      </Carousel.Item>
      <Carousel.Item>
      <img className='domain-logo d-block mx-auto' src={IOT} alt="Internet Of Things" /> {/* Render the image */}
      <div className='titledesc'>
          <h3>Internet Of Things [IOT]</h3>
          <p>Lets make devices communicate</p>
          </div>
      </Carousel.Item>
      <Carousel.Item>
      <img className='domain-logo d-block mx-auto' src={AI} alt="AI Tools" /> {/* Render the image */}
      <div className='titledesc'>
          <h3>Prompt Engineering</h3>
          <p>Explore the limits in AI</p>
          </div>
      </Carousel.Item>
    </Carousel>
  );
}

export default ControlledCarousel;