import React, { useState, useEffect } from "react";
import People from "../components/Members/People";
import Scroll from "../components/Members/Scroll";
import FadeIn from "../components/FadeIn";
import Baselayout from "../components/BaseLayout";
import SilverRankImage from "../assets/images/Silver.png";
import GoldRankImage from "../assets/images/Gold.png";
import DiamondRankImage from "../assets/images/diamond.png";
import CopperRankImage from "../assets/images/Copper.png";
import BronzeRankImage from "../assets/images/Bronze.png";
import Marquee from "react-fast-marquee";
import axios from "axios";

export default function League() {
  const [members, setMembers] = useState([]);

  useEffect(() => {
    axios
      .get(
        "https://pattarai-utilities-v2.herokuapp.com/sheets?id=1k47v36NhTXsZQ89E5IU6pp5ftGqRtnUpRB4v2-pFjAE&range=Website+-+League!A2:G88&cache=true"
      )
      .catch(function (error) {
        console.log(error);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      })
      .then((res) => {
        console.log(res);
        setMembers(res.data);
        setTimeout(() => {}, 3000);
      });
  }, []);

  return (
    <>
      <FadeIn>
        <Baselayout>
          <div className="p-1 mt-5 text-center">
            <h1 className="heading g-text2 m-3 text-center font-weight-bold">
              Our League
            </h1>
            <div className="">
              <Scroll />
              <section
                id="Diamond"
                className="d-md-flex d-block justify-content-center align-items-center mt-5"
              >
                <div className="col-12 col-md-2">
                  <div className="d-flex justify-content-center flex-column align-items-center">
                    <img
                      className="go"
                      src={DiamondRankImage}
                      alt=""
                      style={{ height: "250px", width: "250px" }}
                    />
                    <h5
                      className="font-weight-bold text-uppercase text-center pt-3"
                      style={{ marginTop: "-30px" }}
                    >
                      Diamond
                    </h5>
                  </div>
                </div>
                <div className="col-12 col-md-8 d-flex justify-content-start align-items-center members">
                  <Marquee
                    direction="right"
                    speed={80}
                    pauseOnHover
                    gradient
                    gradientWidth={100}
                    gradientColor={[0, 0, 0]}
                  >
                    {members.map((member, i) => {
                      if (member[2].toUpperCase() === "DIAMOND") {
                        return (
                          <People
                            key={i}
                            name={member[1]}
                            img={member[3]}
                            profileLink={member[4]}
                            emailLink={member[5]}
                            linkedinLink={member[6]}
                          />
                        );
                      } else {
                        return null;
                      }
                    })}
                  </Marquee>
                </div>
              </section>

              <div className="divider div-transparent"></div>
              <section
                id="Gold"
                className="d-md-flex d-block justify-content-center align-items-center mt-5"
              >
                <div className="col-12 col-md-2">
                  <div className="d-flex justify-content-center flex-column align-items-center">
                    <img
                      className="go"
                      src={GoldRankImage}
                      alt=""
                      style={{ height: "250px", width: "250px" }}
                    />
                    <h5
                      className="font-weight-bold text-uppercase text-center pt-3"
                      style={{ marginTop: "-30px" }}
                    >
                      Gold
                    </h5>
                  </div>
                </div>
                <div className="col-12 col-md-8 d-flex justify-content-start align-items-center members">
                  <Marquee
                    direction="right"
                    speed={80}
                    pauseOnHover
                    gradient
                    gradientWidth={100}
                    gradientColor={[0, 0, 0]}
                  >
                    {members.map((member, i) => {
                      if (member[2].toUpperCase() === "GOLD") {
                        return (
                          <People
                            key={i}
                            name={member[1]}
                            img={member[3]}
                            profileLink={member[4]}
                            emailLink={member[5]}
                            linkedinLink={member[6]}
                          />
                        );
                      } else {
                        return null;
                      }
                    })}
                  </Marquee>
                </div>
              </section>

              <div className="divider div-transparent"></div>
              <section
                id="Silver"
                className="d-md-flex d-block justify-content-center align-items-center mt-5"
              >
                <div className="col-12 col-md-2">
                  <div className="d-flex justify-content-center flex-column align-items-center">
                    <img
                      className="go"
                      src={SilverRankImage}
                      alt=""
                      style={{ height: "250px", width: "250px" }}
                    />
                    <h5
                      className="font-weight-bold text-uppercase text-center pt-3"
                      style={{ marginTop: "-30px" }}
                    >
                      Silver
                    </h5>
                  </div>
                </div>
                <div className="col-12 col-md-8 d-flex justify-content-start align-items-center members">
                  <Marquee
                    direction="right"
                    speed={80}
                    pauseOnHover
                    gradient
                    gradientWidth={100}
                    gradientColor={[0, 0, 0]}
                  >
                    {members.map((member, i) => {
                      if (member[2].toUpperCase() === "SILVER") {
                        return (
                          <People
                            key={i}
                            name={member[1]}
                            img={member[3]}
                            profileLink={member[4]}
                            emailLink={member[5]}
                            linkedinLink={member[6]}
                          />
                        );
                      } else {
                        return null;
                      }
                    })}
                  </Marquee>
                </div>
              </section>

              <div className="divider div-transparent"></div>
              <section
                id="Bronze"
                className="d-md-flex d-block justify-content-center align-items-center mt-5"
              >
                <div className="col-12 col-md-2">
                  <div className="d-flex justify-content-center flex-column align-items-center">
                    <img
                      className="bro"
                      src={BronzeRankImage}
                      alt=""
                      style={{ height: "250px", width: "250px" }}
                    />
                    <h5
                      className="font-weight-bold text-uppercase text-center pt-3"
                      style={{ marginTop: "-30px" }}
                    >
                      Bronze
                    </h5>
                  </div>
                </div>
                <div className="col-12 col-md-8 d-flex justify-content-start align-items-center members">
                  <Marquee
                    direction="right"
                    speed={80}
                    pauseOnHover
                    gradient
                    gradientWidth={100}
                    gradientColor={[0, 0, 0]}
                  >
                    {members.map((member, i) => {
                      if (member[2].toUpperCase() === "BRONZE") {
                        return (
                          <People
                            key={i}
                            name={member[1]}
                            img={member[3]}
                            profileLink={member[4]}
                            emailLink={member[5]}
                            linkedinLink={member[6]}
                          />
                        );
                      } else {
                        return null;
                      }
                    })}
                  </Marquee>
                </div>
              </section>
              <div className="divider div-transparent"></div>
              <section
                id="Copper"
                className="d-md-flex d-block justify-content-center align-items-center mt-5"
              >
                <div className="col-12 col-md-2">
                  <div className="d-flex justify-content-center flex-column align-items-center">
                    <img
                      className="cop"
                      src={CopperRankImage}
                      alt=""
                      style={{ height: "250px", width: "250px" }}
                    />
                    <h5
                      className="font-weight-bold text-uppercase text-center pt-3"
                      style={{ marginTop: "-30px" }}
                    >
                      Copper
                    </h5>
                  </div>
                </div>
                <div className="col-12 col-md-8 d-flex justify-content-start align-items-center members">
                  <Marquee
                    direction="right"
                    speed={80}
                    pauseOnHover
                    gradient
                    gradientWidth={100}
                    gradientColor={[0, 0, 0]}
                  >
                    {members.map((member, i) => {
                      if (member[2].toUpperCase() === "COPPER") {
                        return (
                          <People
                            key={i}
                            name={member[1]}
                            img={member[3]}
                            profileLink={member[4]}
                            emailLink={member[5]}
                            linkedinLink={member[6]}
                          />
                        );
                      } else {
                        return null;
                      }
                    })}
                  </Marquee>
                </div>
              </section>
            </div>
          </div>
        </Baselayout>
      </FadeIn>
      {/* ) : (
      <Baselayout>
        <h1 className="full-vh d-flex align-justify-center display-3">
          Under Construction 🚧
        </h1>
      </Baselayout> */}
    </>
  );
}
