import React, { useEffect } from "react";

const Reset = ({ socket }) => {
  useEffect(() => {
    socket.emit("reset", (data) => {
      console.log(data, "reset");
    });
  }, []);

  return <div>Reset</div>;
};

export default Reset;
