import React from "react";

export default function Error404() {
  return (
    <>
      <section className="full-vh d-flex align-justify-center">
        <div className="text-center">
          <h1 className="g-text" style={{ fontSize: "30vw" }}>
            404
          </h1>
          <div>
            <span className="h1 pr-4">Are you Lost?</span>
            <a
              href="/"
              className="font-weight-bold gradient-underline position-relative"
            >
              pattarai.co.in
            </a>
          </div>
        </div>
      </section>
    </>
  );
}
