import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function DragMotion({ dataTip, icon }) {
  return (
    <>
      <motion.div
        drag
        dragElastic={1}
        dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
        data-tip={dataTip}
        className="btn btn-dark rounded-circle"
        style={{
          width: "75px",
          height: "75px",
          marginBottom: "50px",
          marginTop: "50px",
        }}
      >
        <FontAwesomeIcon
          className="mt-3"
          color="#9193fe"
          size="2x"
          icon={icon}
        />
        <p className="d-flex justify-content-center align-items-center mt-4">
          {dataTip}
        </p>
      </motion.div>
    </>
  );
}
