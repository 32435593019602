import { faEnvelope, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function People({
  emailLink,
  profileLink,
  linkedinLink,
  img,
  name,
}) {
  return (
    <section className="mx-5 d-flex justify-content-center align-items-center p-5">
      <div className="zoom-effect">
        <div className="more-info">
          <Links
            emailLink={emailLink}
            profileLink={profileLink}
            linkedinLink={linkedinLink}
          />
        </div>
        <img
          className="bitmoji pl-3 pr-3"
          style={{ width: "150px" }}
          src={img}
          alt="mem"
        />
        <div className="mt-3 d-flex justify-content-center px-3 text-white text-uppercase">
          <p className="w-75">{name}</p>
        </div>
      </div>
    </section>
  );
}

function Links(props) {
  return (
    <div>
      <a
        href={props.emailLink}
        className="btn btn-dark rounded-circle"
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon color="#C33764" icon={faEnvelope} />
      </a>
      <a
        href={props.profileLink}
        className="btn btn-dark rounded-circle info"
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon color="#43C6AC" icon={faInfoCircle} />
      </a>
      <a
        href={props.linkedinLink}
        className="btn btn-dark rounded-circle"
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon color="#1565C0" icon={faLinkedin} />
      </a>
    </div>
  );
}
