import React from "react";
import Footer from "./Footer";
import Header from "./Header";
// import Navbar from "./Navbar";
// import Img from "../assets/images/pattarai_text_white.png";

const Baselayout = (props) => (
  <>
    <Header />
    <section className="container-fluid pt-4">{props.children}</section>
    <Footer />
  </>
);

export default Baselayout;
