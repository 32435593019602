import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { React } from "react";

import CarpeDiem from "./pages/CarpeDiem";
import Home from "./pages/Home";
import About from "./pages/About";
import Projects from "./pages/Projects";
import Events from "./pages/Events";
import Policy from "./pages/Policy";
import Hacktoberfest from "./pages/Hacktoberfest";
import League from "./pages/League";
import Crew from "./pages/Crew";
import Contact from "./pages/Contact";
import Genesis from "./pages/Genesis";
import Error404 from "./pages/Error404";
import Leaderboard from "./pages/Leaderboard";

import ScrollTop from "./components/ScrollTop";
import { io } from "socket.io-client";
import Reset from "./pages/Reset";

const socket = io.connect("https://carpediem.onrender.com");
const ExternalBlog = () => {
  window.location.href = 'https://blog-pattarai.vercel.app/'; 
  return null;
};
function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route
            exact
            path="/carpediem"
            render={(props) => <CarpeDiem {...props} socket={socket} />}
          />
          <Route exact path="/CarpeDiem" component={CarpeDiem} />
          <Route exact path="/Genesis" component={Genesis} />
          <Route exact path="/projects" component={Projects} />
          <Route exact path="/events" component={Events} />
          <Route exact path="/about" component={About} />
          <Route exact path="/league" component={League} />
          <Route exact path="/crew" csomponent={Crew} />
          <Route
            exact
            path="/resetTimer"
            render={(props) => <Reset {...props} socket={socket} />}
          />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/Blog" component={ExternalBlog} />
          <Route exact path="/policy" component={Policy} />
          <Route exact path="/hacktoberfest" component={Hacktoberfest} />
          <Route exact path="/leaderboard" component={Leaderboard} />
          <Route exact path="/404" component={Error404} />
          <Redirect to="/404" />
        </Switch>
        <ScrollTop />
      </Router>
    </>
  );
}

export default App;
