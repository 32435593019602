import React from "react";
import FadeIn from "../components/FadeIn";
import { useRef } from "react";
import Baselayout from "../components/BaseLayout";
import P from "../assets/images/P.svg";
import Al from "../assets/images/A_left.svg";
import Ar from "../assets/images/A_right.svg";
import Tr from "../assets/images/T_right.svg";
import Tl from "../assets/images/T_left.svg";
import R from "../assets/images/R.svg";
import I from "../assets/images/I.svg";

export default function Homev2() {
  const PRef = useRef(null);
  function handlePClick() {
    PRef.current.scrollIntoView({ behavior: "smooth" });
  }
  const ArRef = useRef(null);
  function handleArClick() {
    ArRef.current.scrollIntoView({ behavior: "smooth" });
  }
  const TlRef = useRef(null);
  function handleTlClick() {
    TlRef.current.scrollIntoView({ behavior: "smooth" });
  }
  const TrRef = useRef(null);
  function handleTrClick() {
    TrRef.current.scrollIntoView({ behavior: "smooth" });
  }
  const Al2Ref = useRef(null);
  function handleAl2Click() {
    Al2Ref.current.scrollIntoView({ behavior: "smooth" });
  }
  const RRef = useRef(null);
  function handleRClick() {
    RRef.current.scrollIntoView({ behavior: "smooth" });
  }
  const Al3Ref = useRef(null);
  function handleAl3Click() {
    Al3Ref.current.scrollIntoView({ behavior: "smooth" });
  }
  const IRef = useRef(null);
  function handleIClick() {
    IRef.current.scrollIntoView({ behavior: "smooth" });
  }
  return (
    <FadeIn>
      <Baselayout>
        <main className="d-flex">
          <section className="text-center d-none d-md-block pl-5">
            <div className="home-pagination position-sticky">
              <p className="mb-1" onClick={handlePClick}>
                P
              </p>
              <p className="mb-1" onClick={handleArClick}>
                A
              </p>
              <p className="mb-1" onClick={handleTlClick}>
                T
              </p>
              <p className="mb-1" onClick={handleTrClick}>
                T
              </p>
              <p className="mb-1" onClick={handleAl2Click}>
                A
              </p>
              <p className="mb-1" onClick={handleRClick}>
                R
              </p>
              <p className="mb-1" onClick={handleAl3Click}>
                A
              </p>
              <p className="mb-1" onClick={handleIClick}>
                I
              </p>
            </div>
          </section>
          <section className="mx-4" style={{ width: "100vw" }}>
            <section
              ref={PRef}
              className="vh-100 d-flex flex-column-reverse flex-md-row align-justify-center mt-4"
            >
              <div className="d-flex align-justify-center w-100 mt-4 mt-md-0">
                <div className="px-md-5">
                  <h2 style={{ fontSize: "1.75em" }} className="">
                    <span className="g-text2">Practice</span> and
                    <span className="g-text2"> Preach</span>
                  </h2>
                  <p className="pt-3">
                    PATTARAI is LICET's flagship tech community, equipping
                    students with contemporary technical skills needed to face
                    the industry. We research, teach, learn, and construct
                    innovative technology.
                  </p>
                </div>
              </div>
              <div className="mt-3 mt-md-0 d-flex align-items-center justify-content-center w-100">
                <img height="auto" width="65%" src={P} alt="" />
              </div>
            </section>

            <section
              ref={ArRef}
              className="vh-100 d-flex flex-column flex-md-row align-justify-center"
            >
              <div className="d-flex align-justify-center w-100">
                <img height="auto" width="65%" src={Ar} alt="" />
              </div>
              <div className="d-flex align-justify-center w-100">
                <div className="px-md-5">
                  <h2 style={{ fontSize: "1.75em" }} className="mt-4">
                    Are <span className="g-text2">you</span> curious?
                    <br className="" />
                    So are <span className="g-text2">we</span>!
                  </h2>
                  <p className="mt-3">
                    From Textbooks to Technologies - The community focuses on
                    making the engineers feel engineering rather than read them.
                  </p>
                </div>
              </div>
            </section>

            <section
              ref={TlRef}
              className="vh-100 d-flex flex-column-reverse flex-md-row align-justify-center"
            >
              <div className="d-flex align-justify-center w-100 mt-4 mt-md-0">
                <div className="px-md-5">
                  <h2 style={{ fontSize: "1.75em" }} className="">
                    <span className="g-text2">Terrific results</span>
                    <br className="d-none d-md-block" /> are with a cost.
                  </h2>
                  <p className="pt-3">
                    The community is completely run by students and has
                    conducted more than 100 learning sessions, 10+ workshops and
                    a number of events and more than 20 projects in the
                    pipeline.
                  </p>
                </div>
              </div>
              <div className="mt-3 mt-md-0 d-flex align-items-center justify-content-center w-100">
                <img height="auto" width="65%" src={Tl} alt="" />
              </div>
            </section>

            <section
              ref={TrRef}
              className="vh-100 d-flex flex-column flex-md-row align-justify-center"
            >
              <div className="d-flex align-justify-center w-100">
                <img height="auto" width="65%" src={Tr} alt="" />
              </div>
              <div className="d-flex align-justify-center w-100 mt-4 mt-md-0">
                <div className="px-md-5">
                  <h2 style={{ fontSize: "1.75em" }} className="mt-3 mt-md-0">
                    <span className="g-text2">Talents</span> are encouraged
                    <br className="d-none d-md-block" /> to
                    <span className="g-text2"> inspire</span>.
                  </h2>
                  <p className="pt-3">
                    The community mentors budding engineers. We focus on giving
                    back to the community hence the ultimate learning - teaching
                    cycle is an extensive feature of Pattarai.
                  </p>
                </div>
              </div>
            </section>

            <section
              ref={Al2Ref}
              className="vh-100 d-flex flex-column-reverse flex-md-row align-justify-center"
            >
              <div className="d-flex align-justify-center w-100 mt-4 mt-md-0">
                <div className="px-md-5">
                  <h2 style={{ fontSize: "1.75em" }} className="">
                    An <span className="g-text2">Aspiring</span> community
                    <br />
                    of <span className="g-text2">400+</span> members.
                  </h2>
                  <p className="pt-3">
                    Our community has 400+ aspiring members who work for a
                    positive change. Join our community to learn and teach
                    technologies of the future.
                  </p>
                </div>
              </div>
              <div className="d-flex align-justify-center w-100">
                <img height="auto" width="70%" src={Al} alt="" />
              </div>
            </section>

            <section
              ref={RRef}
              className="vh-100 d-flex flex-column flex-md-row align-justify-center"
            >
              <div className="d-flex align-justify-center w-100">
                <img height="auto" width="60%" src={R} alt="" />
              </div>
              <div className="d-flex align-justify-center w-100 mt-4 mt-md-0">
                <div className="px-md-5">
                  <h2 style={{ fontSize: "1.75em" }} className="">
                    <span className="g-text2">Radiating</span>, upskilling
                    <br /> at it's core.
                  </h2>
                  <p className="pt-3">
                    We upskill all around us and encourage others to explore the
                    sea of cutting edge technologies. Radiating skills to build
                    ourselves both technically and non-technically.
                  </p>
                </div>
              </div>
            </section>

            <section
              ref={Al3Ref}
              className="vh-100 d-flex flex-column-reverse flex-md-row align-justify-center"
            >
              <div className="d-flex align-justify-center w-100 mt-4 mt-md-0">
                <div className="px-md-5">
                  <h2 style={{ fontSize: "1.75em" }} className="">
                    <span className="g-text2">Architecting</span> <br />
                    Reality.
                  </h2>
                  <p className="mt-3">
                    Our projects aim to solve problems in the real world.
                    Holding up to industry standards has been a key to the
                    community's success.
                  </p>
                </div>
              </div>
              <div className="d-flex align-justify-center w-100">
                <img height="auto" width="70%" src={Al} alt="" />
              </div>
            </section>

            <section
              ref={IRef}
              className="vh-100 d-flex flex-column flex-md-row align-justify-center"
            >
              <div className="d-flex align-justify-center w-100">
                <img height="auto" width="20%" src={I} alt="" />
              </div>
              <div className="d-flex align-justify-center w-100 mt-4 mt-md-0">
                <div className="px-md-5">
                  <h2 style={{ fontSize: "1.75em" }} className="">
                    <span className="g-text2">Innovation</span> at finest.
                  </h2>
                  <p className="mt-3">
                    Our connections with the startups and the industry have led
                    to Pattarai offering more than 15+ internship opportunities.
                    Our developers are working on a number of projects that
                    include projects that scale up across countries.
                  </p>
                </div>
              </div>
            </section>
          </section>
        </main>
      </Baselayout>
    </FadeIn>
  );
}
