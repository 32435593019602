import React, { useState } from "react";
import Baselayout from "../components/BaseLayout";
import FadeIn from "../components/FadeIn";

export default function Leaderboard() {
  const [leaderboard, setLeaderboard] = useState([
    {
      name: "John Doe",
      coins: 100,
      rank: 1,
    },
  ]);
  return (
    <>
      <FadeIn>
        <Baselayout>
          <section
            className="container w-100 vh-100"
            style={{ marginTop: "6rem" }}
          >
            <h1 className="text-center g-text2 mb-5">Leaderboard</h1>
            <div
              className="card text-white p-3"
              style={{ backgroundColor: "#1d212c", minWidth: "500px" }}
            >
              <div className="d-md-flex justify-content-start align-items-center font-weight-bold">
                <p className="col-2">#</p>
                <p className="col-6">Name</p>
                <p className="col-4 text-right">Coins</p>
              </div>
              {leaderboard &&
                leaderboard.map((item, index) => (
                  <>
                    <hr
                      style={{ backgroundColor: "#ebebeb", opacity: "0.1" }}
                    />
                    <div className="d-md-flex justify-content-start align-items-center">
                      <p className="col-2">{item.rank}</p>
                      <p className="col-6">{item.name}</p>
                      <p className="col-4 text-right">{item.coins}</p>
                    </div>
                  </>
                ))}
            </div>
          </section>
        </Baselayout>
      </FadeIn>
    </>
  );
}
