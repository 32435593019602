import {
  faLinkedin,
  faYoutube,
  faGithub,
  faInstagram,
  faTwitter,
  faFacebook,
  faDiscord,
} from "@fortawesome/free-brands-svg-icons";
import logo from "../assets/images/pattarai_text_white.png";
import SocialIcons from "./SocialIcons";
import Newsletter from "../assets/files/Newsletter.pdf";
import { Link } from "react-router-dom";
import Brochure from "../assets/files/Brochure.pdf";

export default function Footer() {
  const date = new Date();
  const currentYear = date.getFullYear();

  return (
    <>
      <section className="mb-4" style={{ marginTop: "100px" }}>
        <section
          className="pt-4 border-secondary border-top mx-3 d-flex flex-column flex-md-row justify-content-between align-items-lg-center mt-3 px-md-2 px-lg-5"
          style={{ opacity: "0.8" }}
        >
          <Link to="/" className="text-center">
            <img height="45" width="auto" src={logo} alt="Licet Pattarai"></img>
          </Link>
          <div className="text-center mr-md-2 my-4 my-md-0 mt-md-3 mt-lg-0">
            <a
              href="Policy"
              className="mx-2 badge font-weight-normal pb-3 link"
            >
              Privacy Policy
            </a>
            <a
              href="/contact"
              className="mx-2 badge font-weight-normal pb-3 link"
            >
              Contact
            </a>
            <a
              href={Newsletter}
              className="mx-2 badge font-weight-normal pb-3 link"
              target="_blank"
              rel="noreferrer"
            >
              Newsletter
            </a>
            <a
              href={Brochure}
              className="mx-2 badge font-weight-normal pb-3 link"
              target="_blank"
              rel="noreferrer"
            >
              Brochure
            </a>
          </div>
          <div className="h4 text-center">
            <SocialIcons
              img={faLinkedin}
              src={"https://www.linkedin.com/company/licetpattarai"}
            />
            <SocialIcons
              img={faYoutube}
              src={"https://www.youtube.com/channel/UC24MOAmQKzWK5-6DyUaa8Aw"}
            />
            <SocialIcons img={faGithub} src={"https://github.com/pattarai"} />
            <SocialIcons
              img={faInstagram}
              src={"https://www.instagram.com/licetpattarai"}
            />
            <SocialIcons
              img={faTwitter}
              src={"https://twitter.com/licetpattarai"}
            />
            <SocialIcons
              img={faFacebook}
              src={"https://www.facebook.com/licetpattarai"}
            />
            <SocialIcons
              img={faDiscord}
              src={"https://discord.gg/GuMceRXPSQ"}
            />
          </div>
        </section>
        <div className="text-center my-3 ml-md-4 ml-lg-0">
          <span className="badge font-weight-normal text-secondary">
            {" "}
            © {currentYear} · Pattarai · All rights reserved
          </span>
        </div>
      </section>
    </>
  );
}
