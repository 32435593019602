import { Link } from "react-scroll";
import Silver from "../../assets/images/Silver.png";
import Gold from "../../assets/images/Gold.png";
import Diamond from "../../assets/images/diamond.png";
import Copper from "../../assets/images/Copper.png";
import Bronze from "../../assets/images/Bronze.png";

export default function Scroll() {
  const Icon = (
    { src, title, active } // eslint-disable-line
  ) => (
    <div>
      <Link
        className="btn px-0"
        activeClass={active}
        to={title}
        spy={true}
        smooth={true}
      >
        <img src={src} alt={title} className="w-md-75 w-100" />
        {/* <span className="btn btn-sm btn-dark rounded-pill ">Diamond</span> */}
      </Link>
    </div>
  );

  return (
    <section>
      <div>
        <div className="d-flex container justify-content-around">
          <Icon active="active" src={Diamond} title="Diamond" />
          <Icon src={Gold} title="Gold" />
          <Icon src={Silver} title="Silver" />
          <Icon src={Bronze} title="Bronze" />
          <Icon src={Copper} title="Copper" />
        </div>
      </div>

      <div className="divider div-transparent"></div>
    </section>
  );
}
