import DragMotion from "../About/DragMotion";
import { motion } from "framer-motion";
import {
  faPenNib,
  faDraftingCompass,
  faCode,
  faTasks,
  faMicrochip,
  faRobot,
  faCloud,
  faCogs,
  faCloudUploadAlt,
  faLayerGroup,
} from "@fortawesome/free-solid-svg-icons";
import Platform from "../../assets/images/Platform.png";
import media from "../../assets/images/multimedia.png";
import Fabrication from "../../assets/images/Fabrication.png";
import Circuit from "../../assets/images/Circuit-01.png";
export default function OurExp() {
  return (
    <>
      <section className="text-center d-flex flex-column align-justify-center">
        <h1 className="g-text2 font-weight-bold">Our Expertise</h1>
        <div className="container">
          <div className="row align-justify-center">
            <div className="col-lg-2 col-md-3 col-6 text-center">
              <DragMotion icon={faCloud} dataTip="Cloud" />
            </div>
            <div className="col-lg-2 col-md-3 col-6 text-center">
              <DragMotion icon={faPenNib} dataTip="Graphic designing" />
            </div>
            <div className="col-lg-2 col-md-3 col-6 text-center">
              <DragMotion icon={faCogs} dataTip="Automation" />
            </div>
            <div className="col-lg-2 col-md-3 col-6 text-center">
              <DragMotion icon={faCloudUploadAlt} dataTip="PaaS & IaaS" />
            </div>
            <div className="col-lg-2 col-md-3 col-6 text-center">
              <DragMotion icon={faCode} dataTip="Web Development" />
            </div>
            <div className="col-lg-2 col-md-3 col-6 text-center">
              <DragMotion icon={faMicrochip} dataTip="IOT" />
            </div>
            <div className="col-lg-2 col-md-3 col-6 text-center">
              <DragMotion icon={faDraftingCompass} dataTip="Product Design" />
            </div>

            <div className="col-lg-2 col-md-3 col-6 text-center">
              <DragMotion icon={faRobot} dataTip="Machine Learning" />
            </div>
            <div className="col-lg-2 col-md-3 col-6 text-center">
              <DragMotion icon={faTasks} dataTip="Event Management" />
            </div>
            <div className="col-lg-2 col-md-3 col-6 text-center">
              <DragMotion icon={faLayerGroup} dataTip="Software Development" />
            </div>
            <div className="col-lg-2 col-md-3 col-6 text-center">
              <motion.div
                drag
                dragElastic={1}
                dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
                data-tip="Software Development"
                className="btn btn-dark rounded-circle"
                style={{
                  width: "75px",
                  height: "75px",
                  marginBottom: "50px",
                  marginTop: "50px",
                }}
              >
                <img
                  src={Platform}
                  alt=""
                  height="auto"
                  width="100%"
                  style={{ marginTop: "25%" }}
                />
                <p className="d-flex justify-content-center align-items-center mt-4">
                  Cross Platform Applications
                </p>
              </motion.div>
            </div>
            <div className="col-lg-2 col-md-3 col-6 text-center">
              <motion.div
                drag
                dragElastic={1}
                dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
                data-tip="Software Development"
                className="btn btn-dark rounded-circle"
                style={{
                  width: "75px",
                  height: "75px",
                  marginBottom: "50px",
                  marginTop: "50px",
                }}
              >
                <img
                  src={Fabrication}
                  alt=""
                  height="auto"
                  width="80%"
                  style={{ marginTop: "20%" }}
                />
                <p className="d-flex justify-content-center align-items-center mt-4">
                  Fabrication
                </p>
              </motion.div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 text-center">
              <motion.div
                drag
                dragElastic={1}
                dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
                data-tip="Software Development"
                className="btn btn-dark rounded-circle"
                style={{
                  width: "75px",
                  height: "75px",
                  marginBottom: "50px",
                  marginTop: "50px",
                }}
              >
                <img
                  src={Circuit}
                  alt=""
                  height="auto"
                  width="100%"
                  style={{ marginTop: "20%", marginLeft: "5%" }}
                />
                <p className="d-flex justify-content-center align-items-center mt-4">
                  PCB Designing
                </p>
              </motion.div>
            </div>
            <div className="col-lg-2 col-md-3 col-6 text-center">
              <motion.div
                drag
                dragElastic={1}
                dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
                data-tip="Software Development"
                className="btn btn-dark rounded-circle"
                style={{
                  width: "75px",
                  height: "75px",
                  marginBottom: "50px",
                  marginTop: "50px",
                }}
              >
                <img
                  src={media}
                  alt=""
                  height="auto"
                  width="100%"
                  style={{ marginTop: "18%" }}
                />
                <p className="d-flex justify-content-center align-items-center mt-4">
                  Multimedia
                </p>
              </motion.div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
