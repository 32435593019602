import React, { useState, useEffect } from "react";
import Marquee from "react-fast-marquee";
import { motion } from "framer-motion";
import axios from "axios";
import { NewsL } from "../Placeholder";
import FadeIn from "../FadeIn";

function ClientsCard(props) {
  console.log(props.link);
  return (
    <motion.div
      whileHover={{ scale: 1.1, rotate: -5 }}
      className="card m-5 d-flex align-items-center"
      style={{
        backgroundColor: "black",
        borderColor: "#409cfe",
        boxShadow: "2px 2px 1px #f687ff",
        borderWidth: "2px",
        borderRadius: "15px",
        width: "18rem",
      }}
    >
      <div className="card-body d-flex flex-column align-items-center justify-content-center">
        <p className="text-center font-weight-bold h3">{props.title}</p>
        <p style={{ color: "#ebebea" }} className="text-center mt-4">
          {props.content}
        </p>
        <a
          href={props.link}
          target="_blank"
          rel="noreferrer"
          className="btn m-3 text-white bg-black"
          style={{
            borderColor: "#409cfe",
            boxShadow: "2px 2px 1px #f687ff",
          }}
        >
          More info
        </a>
      </div>
    </motion.div>
  );
}

export default function MiniProjects() {
  const [news, setNews] = useState(null);
  const [isLoading, setLoader] = useState(true);

  useEffect(() => {
    axios
      .get(
        "https://pattarai-utilities-v2.herokuapp.com/sheets?id=1k47v36NhTXsZQ89E5IU6pp5ftGqRtnUpRB4v2-pFjAE&range=Website+-+Mini_Projects!A2:D9&cache=false"
      )
      .then((res) => {
        let data = res.data;
        setNews(data);
        setLoader(false);
      });
  }, []);

  return (
    <>
      {isLoading ? (
        <NewsL />
      ) : (
        <FadeIn>
          {news && (
            <section className="vh-75 d-md-flex align-justify-center">
              <Marquee
                pauseOnHover
                direction="left"
                speed={60}
                gradient
                gradientWidth={70}
                gradientColor={[0, 0, 0]}
                className=""
              >
                <motion.div
                  exit={{ opacity: 0 }}
                  initial="initial"
                  animate="animate"
                >
                  <motion.div className="d-flex">
                    {news.map((mem, i) => {
                      return (
                        <ClientsCard
                          key={i}
                          title={mem[1]}
                          content={mem[2]}
                          link={mem[3]}
                        />
                      );
                    })}
                  </motion.div>
                </motion.div>
              </Marquee>
              <div className="h1 ml-0 ml-md-5 text-md-right text-center d-flex align-justify-center g-text2 pr-md-5 pr-0 px-3 px-md-0">
                More Projects.
              </div>
            </section>
          )}
        </FadeIn>
      )}
    </>
  );
}
