import {
  faEnvelope,
  faMobileAlt,
  faMapMarkerAlt,
  faHandshake,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TerminalController } from "../components/Contact/Terminal";
import FadeIn from "../components/FadeIn";
import DiscordBlurple from "../assets/images/icon_clyde_white_RGB.png";
import CirclePattaraiWhite from "../assets/images/pattarai-circle-white.png";
import LicetWhite from "../assets/images/licet-white.png";
import {
  faLinkedin,
  faYoutube,
  faGithub,
  faInstagram,
  faTwitter,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons";
import SocialHandles from "../components/Contact/SocialHandles";
import Baselayout from "../components/BaseLayout";

export default function Contact() {
  return (
    <>
      <FadeIn>
        <Baselayout>
          <main className="mt-3 d-flex flex-column align-justify-center text-center w-100">
            <section className="d-md-flex align-items-center justify-content-end mt-5 w-100 min-vh-75">
              <h1
                style={{ fontSize: "4rem" }}
                className="m-4 text-center text-md-right font-weight-bold"
              >
                <span className="g-text2">Get in Touch</span>
                <br />
                <p className="font-weight-normal text-capitalize h3">
                  We 💖 to talk ;)
                </p>
              </h1>
              <div
                style={{ marginRight: "-10px" }}
                className="d-md-flex py-4 px-3 justify-content-between align-items-center flex-column"
              >
                <SocialHandles
                  img={faLinkedin}
                  src={"https://www.linkedin.com/company/licetpattarai"}
                />
                <SocialHandles
                  img={faYoutube}
                  src={
                    "https://www.youtube.com/channel/UC24MOAmQKzWK5-6DyUaa8Aw"
                  }
                />
                <SocialHandles
                  img={faGithub}
                  src={"https://github.com/pattarai"}
                />
                <SocialHandles
                  img={faInstagram}
                  src={"https://www.instagram.com/licetpattarai"}
                />
                <SocialHandles
                  img={faTwitter}
                  src={"https://twitter.com/licetpattarai"}
                />
                <SocialHandles
                  img={faFacebook}
                  src={"https://www.facebook.com/LicetPattaraiAdmin"}
                />
              </div>

              <TerminalController />
            </section>

            <div className="mt-5 p-md-5 d-md-flex container p-0 text-center text-md-right justify-content-center align-items-center min-vh-75">
              <div className="col-md-9 col-12 p-md-5">
                <h1 className="font-weight-bold g-text2">
                  Pattarai's Discord Community
                </h1>
                <p className="mt-4 text-secondary">
                  We're passionate about connecting with people to talk about
                  what we are doing at Pattarai! Our Discord Server is the heart
                  and soul of our community. Join our ever-growing Discord
                  community to get help, ask questions, to get to know each
                  other, share ideas, flex your projects, collborate on
                  projects, chat with fella engineers, and much more! Whether
                  you have questions about our technology or just want to
                  critique something about us, we welcome you.
                </p>
                <a
                  className="my-3 btn btn-lg btn-light font-weight-bold"
                  href="https://discord.gg/GuMceRXPSQ"
                >
                  JOIN DISCORD
                </a>
              </div>
              <div className="col-md-3 col-12 d-flex align-items-center flex-column justify-content-center">
                <div className="">
                  <img
                    style={{ width: "200px" }}
                    src={DiscordBlurple}
                    alt=""
                    srcset=""
                  />
                </div>
                <h1
                  style={{ fontSize: "3.5rem" }}
                  className="font-weight-bold mt-3 d-flex text-center text-md-left"
                >
                  <FontAwesomeIcon
                    className="fa-icons px-2 mt-2"
                    size="1x"
                    icon={faHandshake}
                  />
                  <span> 600+</span>
                </h1>
              </div>
            </div>

            <div className="container d-flex d-md-block align-justify-center vh-100 vh-md-75">
              <div className="row align-justify-center">
                <div className="col-12 col-md-2 d-flex flex-md-column justify-content-around align-items-md-end vh-md-50">
                  <img
                    src={CirclePattaraiWhite}
                    style={{ width: "110px" }}
                    alt=""
                    srcset=""
                  />
                  <img
                    src={LicetWhite}
                    style={{ width: "110px" }}
                    alt=""
                    srcset=""
                  />
                </div>
                <div className="col-12 col-md-10 contact-iframe text-center vh-50 my-3">
                  <iframe
                    className="rounded"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15546.514334152142!2d80.2336586!3d13.0592975!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xcfedaad4ca5bd750!2sLICET%20%3A%20Loyola-ICAM%20College%20of%20Engineering%20and%20Technology!5e0!3m2!1sen!2sin!4v1629815497165!5m2!1sen!2sin"
                    height="100%"
                    width="100%"
                    frameBorder="0"
                    allowFullScreen=""
                    title="map"
                  />
                </div>
              </div>
            </div>

            <div className="d-md-flex justify-content-around align-items-md-start getin-touch w-100">
              <div className="pt-4 pt-md-0">
                <FontAwesomeIcon
                  className="fa-icons"
                  size="2x"
                  icon={faEnvelope}
                />
                <p className="mt-4">
                  <h6 className="mt-2 getin-touch-txt">EMAIL</h6>
                  <a href="mailto:pattarai@licet.ac.in">
                    {" "}
                    pattarai@licet.ac.in
                  </a>
                </p>
              </div>

              {/* <p className="v-line"></p> */}
              <div className="pt-4 pt-md-0">
                <FontAwesomeIcon
                  className="fa-icons"
                  size="2x"
                  icon={faMapMarkerAlt}
                />
                <p className="mt-4">
                  <h6 className="mt-2 getin-touch-txt">ADDRESS</h6>
                  Loyola Campus, Nungambakkam, <br />
                  Chennai - 600 034
                </p>
              </div>

              {/* <p className="v-line"></p> */}
              <div className="pt-4 pt-md-0">
                <FontAwesomeIcon
                  className="fa-icons"
                  size="2x"
                  icon={faMobileAlt}
                />
                <p className="mt-4">
                  <h6 className="mt-2 getin-touch-txt">PHONE</h6>
                  <a href="tel:+91 6381 813 202"> +91 6381 813 202</a>
                </p>
              </div>
            </div>
          </main>
        </Baselayout>
      </FadeIn>
    </>
  );
}
