import React from "react";
import "../assets/scss/navbar.scss";
import "../assets/scss/genesis.scss";
// import Img from "../assets/images/pattarai_text_white.png";
import CircleImg from "../assets/images/pattarai-circle-white.png";
import { Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function Header() {
  return (
    <>
      <Navbar
        className="py-3 fixed-top"
        collapseOnSelect
        expand="lg"
        variant="dark"
      >
        <Navbar.Brand href="/">
          <div className="d-flex align-items-center">
            <img
              src={CircleImg}
              alt=""
              height="50"
              className="w-100 d-xs-block d-sm-none"
            />
            <p
              className="d-none d-sm-block mb-0 ml-3 ml-md-0"
              style={{ letterSpacing: "5px", fontSize: "20px" }}
            >
              LICET PATTARAI
            </p>
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          style={{
            zIndex: "7",
          }}
          id="responsive-navbar-nav"
        >
          <Nav className="mr-auto"></Nav>
          <Nav className="nav-title">
            <Link to="/about" className="nav-link  mx-2 mt-3 mt-md-2">
              About
            </Link>
            <Link to="/events" className="nav-link  m-2">
              Events
            </Link>
            <Link to="/projects" className="nav-link  m-2">
              Projects
            </Link>
            <Link to="/Genesis" className="nav-link  m-2">
              Genesis
            </Link>
            <Link to="/CarpeDiem" className="nav-link  m-2">
              CarpeDiem
            </Link>
            <Link to="/Blog" className="nav-link  m-2">
              Blog
            </Link>
            <Link to="/contact" className="nav-link  m-2">
              Contact
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}
