import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { Canvas } from "react-three-fiber";
import {
  Triangle1,
  Diamond,
  Torusknot,
  Ring,
  Triangle,
  Icosahedron,
  Sphere1,
  Light1,
  Light2,
  Light3,
} from "../components/Events/Shapes";
import Hacktoberfest from "../assets/images/Hack.jpeg";
import FadeIn from "../components/FadeIn";
import Baselayout from "../components/BaseLayout";
import Marquee from "react-fast-marquee";
import { motion } from "framer-motion";
import react from "../assets/images/react.png";
import code from "../assets/images/code.png";
import flutter from "../assets/images/flutter.png";
import CS from "../assets/images/CS.png";
import pen from "../assets/images/pen.png";
import IOT from "../assets/images/IOT.png";

export default function Event() {
  function WorkshopCard(props) {
    return (
      <div
        className="card card-body m-5 d-flex align-items-center justify-content-center"
        style={{
          backgroundColor: "black",
          borderColor: "#409cfe",
          boxShadow: "2px 2px 1px #f687ff",
          borderWidth: "2px",
          borderRadius: "15px",
          width: "18rem",
          height: "28rem",
        }}
      >
        <div className="">
          <div>
            <img style={{ width: "100px" }} alt="Card cap" src={props.src} />
          </div>
          <p className="h4 font-weight-bold mt-2 mb-3">{props.workshopTitle}</p>
          {props.speakerLinks.map((handle) => (
            <a
              href={"https://twitter.com/" + handle}
              target="_blank"
              rel="noreferrer"
            >
              <div style={{ color: "#6c94d4" }}>{"@" + handle}</div>
            </a>
          ))}

          <a
            href={props.link}
            rel="noreferrer"
            target="_blank"
            className="m-3 btn "
            style={{
              color: "white",
              borderColor: "#409cfe",
              boxShadow: "2px 2px 1px #f687ff",
              backgroundColor: "black",
            }}
          >
            Learn More
          </a>
        </div>
      </div>
    );
  }

  return (
    <>
      <FadeIn>
        <Baselayout>
          <div className="text-center mt-5 pt-md-3 pt-1">
            <h1 className="mt-5 pb-3 heading text-center event font-weight-bold g-text2">
              CARPEDIEM 2.0
            </h1>
            <div className="divider div-transparent"></div>
            <div className="divider div-transparent"></div> 
            <VerticalTimeline>
              <VerticalTimelineElement
                className="vertical-timeline-element--education text-left"
                date="28/02/2024"
                contentStyle={{
                  background: "rgb(0,0,0)",
                  color: "rgb(211,211,211)",
                }}
                iconStyle={{ background: "#000000" }}
              ><h3>
              Online Registration Started.
            </h3>
                <Canvas className="retrofy" colorManagement>
                  <ambientLight intensity={0.3} />
                  <pointLight position={[10, 0, 0]} intensity={0.5} />

                  <Light1 brightness={45} color={"#3751DD"} />
                  <Light2 brightness={45} color={"#C229C2"} />
                  <Light3 brightness={45} color={"#18A2C2"} />

                  <Icosahedron />
                </Canvas>
              </VerticalTimelineElement>

              <VerticalTimelineElement
                className="vertical-timeline-element--education text-left"
                style={{ TextSize: "30px" }}
                date=" 02/03/2024"
                contentStyle={{
                  background: "rgb(0,0,0)",
                  color: "rgb(211,211,211)",
                }}
                iconStyle={{ background: "#000000" }}
              > <h3>
              Registration Ends
             </h3>
                <Canvas className="retrofy" colorManagement>
                  <ambientLight intensity={0.3} />
                  <pointLight position={[10, 0, 0]} intensity={0.5} />
                  <Triangle />

                  <Light1 brightness={45} color={"#3751DD"} />
                  <Light2 brightness={45} color={"#C229C2"} />
                  <Light3 brightness={45} color={"#18A2C2"} />
                </Canvas>
               
              </VerticalTimelineElement>

              <VerticalTimelineElement
                className="vertical-timeline-element--education text-left"
                date="03/03/2024"
                contentStyle={{
                  background: "rgb(0,0,0)",
                  color: "rgb(211,211,211)",
                }}
                iconStyle={{ background: "#000000" }}
                // icon={<WorkIcon />}
              >
                <h3>
                  Round 1 (Online Screening) starts
                </h3>
                <Canvas className="retrofy" colorManagement>
                  <ambientLight intensity={0.3} />
                  <pointLight position={[10, 0, 0]} intensity={0.5} />

                  <Ring />

                  <Light1 brightness={45} color={"#3751DD"} />
                  <Light2 brightness={45} color={"#C229C2"} />
                  <Light3 brightness={45} color={"#18A2C2"} />
                </Canvas>

              </VerticalTimelineElement>

              <VerticalTimelineElement
                className="vertical-timeline-element--education text-left"
                date="05/03/2024 "
                contentStyle={{
                  background: "rgb(0,0,0)",
                  color: "rgb(211,211,211)",
                }}
                iconStyle={{ background: "#000000" }}
                // icon={<SchoolIcon />}
              >
                <h3>
                 Round 1 Results Published
                </h3>
                <Canvas className="retrofy" colorManagement>
                  <ambientLight intensity={0.3} />
                  <pointLight position={[10, 0, 0]} intensity={0.5} />
                  <Sphere1 />

                  <Light1 brightness={45} color={"#3751DD"} />
                  <Light2 brightness={45} color={"#C229C2"} />
                  <Light3 brightness={45} color={"#18A2C2"} />
                </Canvas>

              </VerticalTimelineElement>

              <VerticalTimelineElement
                className="vertical-timeline-element--education text-left"
                date="04/04/24"
                contentStyle={{
                  background: "rgb(0,0,0)",
                  color: "rgb(211,211,211)",
                }}
                iconStyle={{ background: "#000000" }}
                // icon={<SchoolIcon />}
              >
                <h3>
                 60 Hour Hackathon Starts
                </h3>
                <Canvas className="retrofy" colorManagement>
                  <ambientLight intensity={0.3} />
                  <pointLight position={[10, 0, 0]} intensity={0.5} />
                  <Torusknot />

                  <Light1 brightness={45} color={"#3751DD"} />
                  <Light2 brightness={45} color={"#C229C2"} />
                  <Light3 brightness={45} color={"#18A2C2"} />
                </Canvas>

              </VerticalTimelineElement>
              <VerticalTimelineElement
                className="vertical-timeline-element--education text-left"
                date="06/04/2024"
                contentStyle={{
                  background: "rgb(0,0,0)",
                  color: "rgb(211,211,211)",
                }}
                iconStyle={{ background: "#000000" }}
                // icon={<SchoolIcon />}
              >
                <h3>
              60 Hour Hackathon Ends
              </h3>
                <Canvas className="retrofy" colorManagement>
                  <ambientLight intensity={0.3} />
                  <pointLight position={[10, 0, 0]} intensity={0.5} />
                  <Diamond />

                  <Light1 brightness={45} color={"#3751DD"} />
                  <Light2 brightness={45} color={"#C229C2"} />
                  <Light3 brightness={45} color={"#18A2C2"} />
                </Canvas>

              </VerticalTimelineElement>
              
            </VerticalTimeline>
  
          </div>
        </Baselayout>
      </FadeIn>
    </>
  );
}
