import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleUp } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef } from "react";

export default function ScrollTop() {
  const scrollRef = useRef(null);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 300) {
        scrollRef.current.style.display = "block";
      } else {
        scrollRef.current.style.display = "none";
      }
    });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <p
        className="mb-0"
        ref={scrollRef}
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          cursor: "pointer",
          display: "none",
        }}
        onClick={() => {
          window.scrollTo(0, 0);
        }}
      >
        <FontAwesomeIcon
          className=""
          color="#ffffff"
          size="2x"
          icon={faChevronCircleUp}
        />
      </p>
    </>
  );
}
