import React, { useState } from "react";
import Baselayout from "../components/BaseLayout";
import FadeIn from "../components/FadeIn";
import Loader from "../components/Loader";
import Logo from "../assets/videos/pattarai-logo-glare-3D.mp4";
import News from "../components/Home/News";
import OurExp from "../components/Home/OurExp";
import Brochure from "../assets/files/Brochure.pdf";
import Place from "../assets/images/pattarai-place.png";
import { Button } from "react-bootstrap";
import Bulb from "../assets/images/pink_pattarai.png";

export default function LandingPage() {
  const [loader, setLoader] = useState(true);
  setTimeout(() => {
    setLoader(false);
  }, 3000);
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <FadeIn>
          <Baselayout>
            <section className="vh-100 d-flex align-justify-center mt-5 mt-md-0">
              <div className="container-fluid">
                <div className="row align-justify-center">
                  <div className="col-12 col-md-6 d-flex align-justify-center">
                    <div className="text-center text-md-left">
                      <span className="font-weight-bold pattarai-text ml-3 ml-md-0">
                        PATTARAI
                      </span>
                      <h5
                        style={{ fontWeight: "normal" }}
                        className="licet-text g-text2"
                      >
                        Dream | Design | Develop
                      </h5>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 d-flex align-justify-center">
                    <video
                      id="hero-video"
                      className="w-100"
                      src={Logo}
                      alt="pattarai-main"
                      autoPlay={true}
                      loop
                      muted
                    />
                  </div>
                </div>
              </div>
            </section>

            

            <section className="vh-75 container d-flex align-justify-center">
              <div className="row">
                <div className="col-12 col-md-6 d-flex align-justify-center">
                  <div className="text-right text-md-left">
                    <h2 className="g-text2" style={{ fontWeight: "bold" }}>
                      Picture it, Design it, Develop it
                    </h2>
                    <p style={{ fontSize: "1.4em" }}>
                      If you’re someone who keeps inventing new ideas along the
                      way, with no proper platform to work on it, then you’ve
                      reached the right place. “Pattarai” is a community of
                      industrious engineers working to transform your ideas into
                      functioning projects!
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-6 d-none d-md-flex align-justify-center">
                  <img
                    className=""
                    src={Bulb}
                    alt=""
                    style={{ width: "50vw" }}
                  />
                </div>
              </div>
            </section>

            <section className="vh-100 vh-md-75 container d-flex align-justify-center">
              <div className="row">
                <div
                  className="col-12 col-md-4 d-flex align-justify-center mt-5 mt-md-0"
                  data-aos="fade-right"
                  data-aos-duration="900"
                >
                  <img width="90%" src={Place} alt="" srcset="" />
                </div>
                <div
                  className="col-12 col-md-8 d-flex align-justify-center mt-4 mt-md-0"
                  data-aos="fade-left"
                  data-aos-duration="900"
                >
                  <div className="text-center text-md-right">
                    <h2 className="font-weight-bold g-text2">
                      Catch our drift
                    </h2>
                    <p className="my-4">
                      We would like to spread our community and about us to
                      budding engineers out there to know about us and join our
                      tech-community. We have prepared this brochure for you to
                      get a deeper understanding of the things that we have been
                      doing at the community.
                    </p>
                    <a
                      target="_blank"
                      className="btn font-weight-bold"
                      href={Brochure}
                      rel="noreferrer"
                    >
                      <Button
                        className=""
                        style={{
                          // marginTop: "20px",
                          color: "white",
                          borderColor: "#409cfe",
                          boxShadow: "2px 2px 1px #f687ff",
                          backgroundColor: "black",
                        }}
                      >
                        Download Now
                      </Button>
                    </a>
                  </div>
                </div>
              </div>
            </section>

            <div className="" data-aos="fade-up">
              <OurExp />
            </div>
          </Baselayout>
        </FadeIn>
      )}
    </>
  );
}
