import FadeIn from "../components/FadeIn";
import Baselayout from "../components/BaseLayout";
import hacktoberfestImg from "../assets/images/HacktoberfestXPattarai.png";

export default function Hacktoberfest() {
  return (
    <>
      <FadeIn>
        <Baselayout>
          <section
            className="d-flex justify-content-center container mt-5"
            style={{ width: "100vw" }}
          >
            <div className="text-center">
              <div className="d-flex justify-content-center">
                <img className="w-75 m-5" src={hacktoberfestImg} alt="ok" />
              </div>
              <p
                className="g-text2"
                style={{ fontSize: "5vw", fontWeight: "bold" }}
              >
                FastAI Session
              </p>
              <p className="mb-5" style={{ fontSize: "3vw" }}>
                Hacktoberfest Edition
              </p>
              <h5 className="text-center">Happy #Hacktoberfest2021</h5>
              <br />
              <p className="text-justify" style={{ fontWeight: "150" }}>
                PATTARAI loves Open Source! We bring to you, "FastAI Session |
                Hacktoberfest Edition" - a kickstarter session on Getting
                Started with AI/ML! Join us as we learn to use AI/ML
                applications with ease through PyTorch & FastAI (Easiest to use
                - Deep Learning Library out there)
              </p>
              <br />
              <h6>
                Do you like Artificial Intelligence? You love Open Source? Hop
                in Already! Join us this Hacktoberfest2021 to contribute and
                learn.
              </h6>
              <br />
              <h6 className="text-left">EVENT FLOW</h6>
              <li className="text-justify" style={{ fontWeight: "150" }}>
                The one hour session includes the in’s and out’s of GitHub -
                Commits, Issues & Pull Requests to help you get started with the
                Hacktoberfest.
              </li>
              <li className="text-justify" style={{ fontWeight: "150" }}>
                The second part of the session will brief you, how you can
                contribute to our FastAI notebooks on Github and learn ML along
                with us.
              </li>
              <li className="text-justify" style={{ fontWeight: "150" }}>
                That isn’t all! Become a contributor and get a Hacktoberfest
                T-Shirt and swags when you make 4 successful PRs.
              </li>
              <br />
              <h6 className="text-left">Happy Learning!</h6>
              <p className="text-justify" style={{ fontWeight: "150" }}>
                Speaker: Pon Rahul M, Doctoral Research Fellow - IIM-Bangalore,
                India. <br /> Date & Time: 16th October, 2021 | 7pm
                <br />
                <br />
                Meeting Link:{" "}
                <a
                  href="https://discord.gg/brdYV3zdJN"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://discord.gg/brdYV3zdJN
                </a>
                <br />
                Youtube Live Stream Link:{" "}
                <a
                  href="https://youtu.be/6TUmREetGb0"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://youtu.be/6TUmREetGb0
                </a>
              </p>
              <a
                className="mt-4 btn btn-lg btn-grad font-weight-bold"
                href="https://discord.gg/brdYV3zdJN"
                target="_blank"
                rel="noreferrer"
              >
                JOIN DISCORD
              </a>
            </div>
          </section>
        </Baselayout>
      </FadeIn>
    </>
  );
}
