import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import ProjectCard from "../components/Project/ProjectCard";
import FadeIn from "../components/FadeIn";
import Baselayout from "../components/BaseLayout";
import ProjectsData from "../data/ProjectsData.json";
import MiniProjects from "../components/Project/MiniProjects";

export default function Projects() {
  return (
    <>
      <FadeIn>
        <Baselayout>
          <div className="text-center">
            <h1 className="heading g-text2 mt-5 pt-5 font-weight-bold g-text2">
              Our Projects
            </h1>
            <p className="mt-5">
              If you’re someone who keeps inventing new ideas along the way,
              with no proper platform to work on it, then you’ve reached the
              right place.
            </p>
          </div>
          <div className="divider div-transparent"></div>
          <ResponsiveMasonry columnsCount={3} className="container p-0">
            <Masonry>
              {ProjectsData.map((project) => (
                <ProjectCard key={project.title} data={project} />
              ))}
            </Masonry>
          </ResponsiveMasonry>
          <div id="more-projects">
            <MiniProjects />
          </div>
        </Baselayout>
      </FadeIn>
    </>
  );
}
