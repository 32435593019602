import { Card, Modal, Button } from "react-bootstrap";
import React from "react";
import { faGithub } from "@fortawesome/free-brands-svg-icons";

import { motion } from "framer-motion";
import SocialHandles from "../Contact/SocialHandles";

export default function ProjectCard(props) {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <motion.div className="m-5" whileHover={{ scale: 1.2 }}>
      <Card
        className="bg-black"
        style={{
          borderColor: "#409cfe",
          boxShadow: "2px 2px 1px #f687ff",
          borderWidth: "2px",
          borderRadius: "15px",
        }}
      >
        <div className="card-body">
          <Card.Title
            className="he text-bold text-left mt-1"
            style={{
              color: "white",
              fontSize: "2rem",
            }}
          >
            <div className="d-flex justify-content-center">
              <img
                src={props.data.image}
                alt={props.data.title}
                style={{ maxWidth: "175px", maxHeight: "200px" }}
              />
            </div>

            <div className="text-center font-weight-bold mt-5">
              {props.data.title}
            </div>
          </Card.Title>
          <p className="text-center" style={{ color: "rgba(196, 196, 194)" }}>
            {props.data.summary}
          </p>
          <Card.Body>
            <div className="text-center">
              <Button
                className="m-3"
                onClick={() => setModalShow(true)}
                style={{
                  color: "white",
                  borderColor: "#409cfe",
                  boxShadow: "2px 2px 1px #f687ff",
                  backgroundColor: "black",
                }}
              >
                Know More
              </Button>
            </div>
          </Card.Body>
        </div>

        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="modal-styles"
          style={{
            background: "black",
            borderRadius: "3px",
            boxShadow: "2px 2px 2px rgba(235, 189, 53, 0.6)",
            borderColor: "rgba(235, 189, 53)",
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title
              className="font-weight-bold"
              id="contained-modal-title-vcenter"
            >
              {props.data.title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex justify-content-center">
              <img
                src={props.data.image}
                alt=""
                style={{ maxWidth: "300px", maxHeight: "300px" }}
              />
            </div>
            <p className="mt-5 text-justify"> {props.data.content}</p>
          </Modal.Body>
          <Modal.Footer className="d-flex align-items-center justify-content-center">
            <div className="font-weight-bold">Contributers:</div>
            <div className="text-center">
              {props.data.leads.map((lead) => (
                <a
                  key={lead.name}
                  target="_blank"
                  rel="noreferrer"
                  className="text-black badge badge-pill project-l m-1"
                  href={lead.link}
                >
                  {lead.name}
                </a>
              ))}

              {props.data.team.map((member) => (
                <a
                  key={member.name}
                  target="_blank"
                  rel="noreferrer"
                  className="text-black badge badge-pill badge-light m-1"
                  href={member.link}
                >
                  {member.name}
                </a>
              ))}
            </div>
            <a
              className="text-right rounded-circle bg-dark text-dark py-1"
              target="_blank"
              rel="noreferrer"
              href={props.data.github}
            >
              <SocialHandles img={faGithub} />
            </a>
          </Modal.Footer>
        </Modal>
      </Card>
    </motion.div>
  );
}
